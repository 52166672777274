import React, { useState, useEffect, useContext } from "react";
import {
  Col,
  Container,
  Row,
  Accordion,
  Image,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./NewCheckOut.css";
import { connect } from "react-redux";
import { fetchSingleDeliveryAddressStart } from "../store/actions/DeliveryAction";
import { fetchCartListStart } from "../store/actions/CartsAction";
import SomethingWrong from "../Helper/SomethingWrong";
import Skeleton from "react-loading-skeleton";
import { fetchUserWalletDetailsStart } from "../store/actions/WalletAction";
import { withTranslation, useTranslation } from "react-multi-lang";
import { saveOrdersCartStart, ordersCheckoutStart } from "../store/actions/OrdersAction";
import AddMoneyModal from "./AddMoneyModal";
import {
  useAccount,
} from "wagmi";
import { ConnectKitButton } from "connectkit";
import CheckoutWalletButton from "./CheckoutWalletButton";
import { ErrorMessage, Field, Form as FORM, Formik } from "formik";
import { getCardTokenStart } from "../store/actions/XpayAction";
import * as Yup from "yup";
import PaymentWaitingSec from "./PaymentWaitngSec";

const CheckoutPayment = (props) => {
  const account = useAccount();
  const navigate = useNavigate();
  const t = useTranslation();

  const [skipRender, setSkipRender] = useState(true);
  const [showContent, setShowContent] = useState(true);
  const [paymentStarted, setPaymentStarted] = useState(false);
  const [checkout, setCheckout] = useState(true);
  const [addMoney, setAddMoney] = useState(false);

  const validationSchema = Yup.object().shape({
    card_sec_num: Yup.string().required(t("required")),
    s_code: Yup.string().required(t("required")),
    exp_month: Yup.number()
      .required(t("required"))
      .typeError('Invalid month')
      .min(1, "Invalid month")
      .max(12, "Invalid month"),
    exp_year: Yup.number()
      .typeError('Invalid year')
      .required(t("required"))
      .min(new Date().getFullYear().toString().substr(-2), "Invalid year")
      .max(99, "Invalid month"),
  });

  useEffect(() => {
    if (localStorage.getItem("addessUniqueId")) {
      props.dispatch(fetchSingleDeliveryAddressStart({
        delivery_address_unique_id: localStorage.getItem("addessUniqueId")
      }));
      props.dispatch(fetchCartListStart());
      props.dispatch(fetchUserWalletDetailsStart());
    } else {
      navigate("/checkout-address");
    }
  }, []);

  useEffect(() => {
    if (!skipRender && !props.cartList.loading && Object.keys(props.cartList.data).length > 0 && props.userWalletDetails.data.user_wallet &&
      !props.userWalletDetails.loading && Object.keys(props.userWalletDetails.data).length > 0 && props.cartList.data.user_cart_amount) {
      if (props.userWalletDetails.data.user_wallet.remaining >= props.cartList.data.user_cart_amount.cart_final_amount) {
        setCheckout(true);
      } else {
        setCheckout(false);
      }
    }
  }, [props.userWalletDetails, props.cartList]);

  useEffect(() => {
    if (!skipRender &&
      (!props.singleDeliveryAddress.loading && (props.singleDeliveryAddress.error !== false || !props.singleDeliveryAddress.data.delivery_address)) ||
      (!props.cartList.loading && (props.cartList.error !== false || !props.cartList.data.carts || !props.cartList.data.user_cart_amount)) ||
      (!props.userWalletDetails.loading && (props.userWalletDetails.error !== false || !props.userWalletDetails.data.user_wallet))) {
      setShowContent(false);
    }
  }, [props.singleDeliveryAddress, props.cartList, props.userWalletDetails]);

  const changeAddress = e => {
    e.preventDefault();
    localStorage.removeItem("addessUniqueId");
    navigate("/checkout-address");
  }

  const closeAddMoneyModal = () => {
    setAddMoney(false);
  }

  // const onFormSubmit = (paymentMode, data = {}) => {
  //   props.dispatch(ordersCheckoutStart({
  //     payment_mode: paymentMode,
  //     delivery_address_id: props.singleDeliveryAddress.data.delivery_address.delivery_address_id,
  //     ...data,
  //   }));
  // }

  // useEffect(() => {
  //   if (!skipRender && !props.ordersCheckout.loading && Object.keys(props.ordersCheckout.data).length > 0) {
  //     localStorage.removeItem("addessUniqueId");
  //     navigate("/order-placed");
  //   }
  //   setSkipRender(false);
  // }, [props.ordersCheckout]);

  const handleSubmit = (values) => {
    props.dispatch(getCardTokenStart({
      ...values,
      xpay_merchant_key: "f473ad6ee40afd16463b9294d9abcf61"
    }));
  };

  useEffect(() => {
    if (!skipRender && !props.cardToken.loading && Object.keys(props.cardToken.data).length > 0
      && props.cardToken.data.card_token) {
      setPaymentStarted(true);
    }
    setSkipRender(false);
  }, [props.cardToken]);

  return (
    <>
      <div className="newcheckout-sec">
        {showContent
          ? <Container>
            <Row>
              <Col xl={8} md={12} className="resp-mrg-btm-md">
                <div className="newcheckout-header-sec">
                  <ul className="list-unstyled new-checkout-progress-bar">
                    <li className="step1">
                      <div className="newcheckout-flex active">
                        <div className="count-no">
                          1
                        </div>
                        <h6>{t("address")}</h6>
                      </div>
                    </li>
                    <li className="step2">
                      <div className="newcheckout-flex active">
                        <div className="count-no">
                          2
                        </div>
                        <h6>{t("order_summary")}</h6>
                      </div>
                    </li>
                    <li className="step3">
                      <div className="newcheckout-flex active">
                        <div className="count-no">
                          3
                        </div>
                        <h6>{t("payment")}</h6>
                      </div>
                    </li>
                  </ul>
                </div>
                {!paymentStarted ?
                  <div className="newcheckout-body-wrapper">

                    <div className="step-3">
                      <div className="product-delivery-address-box">
                        {props.singleDeliveryAddress.loading
                          ? <Skeleton height={200} />
                          : props.singleDeliveryAddress.data.delivery_address
                            ? <div className="saved-address-box">
                              <div className="address-details">
                                <h3>{t("deliver_to")}</h3>
                                <h4>{props.singleDeliveryAddress.data.delivery_address.name}</h4>
                                <p className="address-block">
                                  {props.singleDeliveryAddress.data.delivery_address.address}{"\n"}
                                  {props.singleDeliveryAddress.data.delivery_address.city}{" "}
                                  {props.singleDeliveryAddress.data.delivery_address.state}{" "}
                                  {props.singleDeliveryAddress.data.delivery_address.pincode}{"\n"}
                                  {props.singleDeliveryAddress.data.delivery_address.landmark}{"\n"}
                                  {props.singleDeliveryAddress.data.delivery_address.contact_number}
                                </p>
                              </div>
                              <div className="address-btn">
                                <Button
                                  className="address-button"
                                  onClick={changeAddress}
                                >
                                  {t("change")}
                                </Button>
                              </div>
                            </div>
                            : null
                        }
                      </div>
                      <div className="product-order-summary-card">
                        <h3>{t("order_summary")} - <span>{
                          props.cartList.loading
                            ? "..."
                            : props.cartList.data.carts ?
                              props.cartList.data.carts.filter(cart => cart.product.stock_status === 1).length
                              : ""
                        } {t("products")}</span></h3>
                        <div className="address-btn">
                          <Link to="/cart" className="address-button">
                            {t("change")}
                          </Link>
                        </div>
                      </div>
                      <div className="payment-radio-box">
                        {props.cartList.loading ?
                          ""
                          : props.cartList.data.user_cart_amount
                            ? <>
                              {/* Direct Payment Btn */}
                              <div className="payment-sec label-bg dropdown-wallet mt-3">
                                <div className="payment-container">
                                  <h3>Checkout</h3>

                                  <div className="login-form new-checkout-form">
                                    <Formik
                                      initialValues={{
                                        card_sec_num: "",
                                        s_code: "",
                                        exp_month: "",
                                        exp_year: "",
                                      }}
                                      onSubmit={(values) => handleSubmit(values)}
                                      validationSchema={validationSchema}
                                    >
                                      <FORM>
                                        <Row>
                                          <Col md={6}>
                                            <Form.Group className="mb-3">
                                              <Form.Label>{t("card_no")}*</Form.Label>
                                              <Field
                                                type="text"
                                                className="form-control"
                                                name="card_sec_num"
                                                placeholder={t("card_no_placeholder")}
                                              />
                                              <ErrorMessage
                                                component={"div"}
                                                name="card_sec_num"
                                                className="text-danger"
                                              />
                                            </Form.Group>
                                          </Col>
                                          <Col md={6}>
                                            <Form.Group className="mb-3">
                                              <Form.Label>{t("card_cvv")}*</Form.Label>
                                              <Field
                                                type="text"
                                                className="form-control"
                                                name="s_code"
                                                placeholder={t("card_cvv_placeholder")}
                                              />
                                              <ErrorMessage
                                                component={"div"}
                                                name="s_code"
                                                className="text-danger"
                                              />
                                            </Form.Group>
                                          </Col>
                                          <Col md={6}>
                                            <Form.Group className="mb-3">
                                              <Form.Label>{t("card_expiry_month")}*</Form.Label>
                                              <Field
                                                type="text"
                                                className="form-control"
                                                name="exp_month"
                                                placeholder={t("card_expiry_month_placeholder")}
                                              />
                                              <ErrorMessage
                                                component={"div"}
                                                name="exp_month"
                                                className="text-danger"
                                              />
                                            </Form.Group>
                                          </Col>
                                          <Col md={6}>
                                            <Form.Group className="mb-3">
                                              <Form.Label>{t("card_expiry_year")}*</Form.Label>
                                              <Field
                                                type="text"
                                                className="form-control"
                                                name="exp_year"
                                                placeholder={t("card_expiry_year_placeholder")}
                                              />
                                              <ErrorMessage
                                                component={"div"}
                                                name="exp_year"
                                                className="text-danger"
                                              />
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                        <Button
                                          type="submit"
                                          className="address-button"
                                          disabled={props.cardToken.buttonDisable}
                                        >
                                          {props.cardToken.loadingButtonContent ? props.cardToken.loadingButtonContent : "Pay"}
                                        </Button>
                                      </FORM>
                                    </Formik>
                                  </div>

                                </div>
                              </div>
                            </>
                            : null
                        }
                      </div>
                    </div>

                  </div>
                  : <PaymentWaitingSec
                    setPaymentStarted={setPaymentStarted}
                    cardToken={props.cardToken.data.card_token}
                    cartAmount={props.cartList.data.user_cart_amount.cart_final_amount}
                    cartAmountFormatted={props.cartList.data.user_cart_amount.cart_checkout_amount}
                    deliveryAddressId={props.singleDeliveryAddress.data.delivery_address.delivery_address_id}
                    walletAddress={props.cardToken.data.wallet_address}
                  />
                }
              </Col>
              <Col xl={4} md={12}>
                <div className="checkout-box">
                  <div className="cart-box">
                    <div className="cart-header-sec">
                      <h4>{t("price_details")}</h4>
                    </div>
                    {props.cartList.loading
                      ? <Skeleton height={200} />
                      : <div className="cart-body-sec">
                        <div className="cart-summary-info">
                          <div className="cart-summary-header">
                            <p>
                              {t("cart_total")} <span>{props.cartList.data.user_cart_amount.cart_total_amount}</span>
                            </p>
                            <p>
                              {t("discount")} <span>-{props.cartList.data.user_cart_amount.cart_discount_amount}</span>
                            </p>
                          </div>
                          <div className="cart-summary-total-amount">
                            <h5>
                              {t("order_total")} <span>{props.cartList.data.user_cart_amount.cart_checkout_amount}</span>
                            </h5>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          : <div className="text-center">
            <SomethingWrong />
            <Button onClick={() => navigate("/")}>{t("go_home")}</Button>
          </div>
        }
      </div>
      {addMoney ?
        <AddMoneyModal
          addMoney={addMoney}
          closeAddMoneyModal={closeAddMoneyModal}
        />
        : null
      }
    </>
  );
};

const mapStateToPros = (state) => ({
  cartList: state.carts.cartList,
  singleDeliveryAddress: state.delivery.singleDeliveryAddress,
  userWalletDetails: state.wallet.userWalletDetails,
  orderSaveCart: state.orders.orderSaveCart,
  ordersCheckout: state.orders.ordersCheckout,

  cardToken: state.xpay.cardToken,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(CheckoutPayment));
