import {
  SAVE_ORDERS_DIRECT_START,
  SAVE_ORDERS_DIRECT_SUCCESS,
  SAVE_ORDERS_DIRECT_FAILURE,
  SAVE_ORDERS_CART_START,
  SAVE_ORDERS_CART_SUCCESS,
  SAVE_ORDERS_CART_FAILURE,
  FETCH_ORDERS_LIST_START,
  FETCH_ORDERS_LIST_SUCCESS,
  FETCH_ORDERS_LIST_FAILURE,
  FETCH_ORDERS_DETAILS_START,
  FETCH_ORDERS_DETAILS_SUCCESS,
  FETCH_ORDERS_DETAILS_FAILURE,
  CANCEL_ORDERS_START,
  CANCEL_ORDERS_SUCCESS,
  CANCEL_ORDERS_FAILURE,
  INVOICE_ORDERS_START,
  INVOICE_ORDERS_SUCCESS,
  INVOICE_ORDERS_FAILURE,
  FETCH_MORE_ORDERS_LIST_START,
  TRANSACTION_LIST_START,
  TRANSACTION_LIST_SUCCESS,
  TRANSACTION_LIST_FAILURE,
  MORE_TRANSACTION_LIST_START,
  ORDERS_SEND_CANCEL_REQUEST_START,
  ORDERS_SEND_CANCEL_REQUEST_SUCCESS,
  ORDERS_SEND_CANCEL_REQUEST_FAILURE,
  ORDERS_SEND_RETURN_REQUEST_START,
  ORDERS_SEND_RETURN_REQUEST_SUCCESS,
  ORDERS_SEND_RETURN_REQUEST_FAILURE,
  ORDERS_CHECKOUT_START,
  ORDERS_CHECKOUT_SUCCESS,
  ORDERS_CHECKOUT_FAILURE,
  ORDERS_CHECKOUT_STATUS_UPDATE_START,
  ORDERS_CHECKOUT_STATUS_UPDATE_FAILURE,
  ORDERS_CHECKOUT_STATUS_UPDATE_SUCCESS
} from "../actions/ActionConstant";

const initialState = {
  orderSaveDirect: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false
  },
  orderSaveCart: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: null,
    buttonDisable: false
  },
  orderList: {
    data: {},
    loading: true,
    error: false,
  },
  orderDetails: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false
  },
  cancelOrders: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false
  },
  invoiceOrders: {
    data: {},
    loading: true,
    error: false,
  },
  transactionLists: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false
  },
  cancelOrder: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: null,
    buttonDisable: false,
  },
  returnOrder: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: null,
    buttonDisable: false,
  },
  ordersCheckout: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: null,
    buttonDisable: false,
  },
  ordersCheckoutStatusUpdate: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: null,
    buttonDisable: false,
  }


};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_ORDERS_DIRECT_START:
      return {
        ...state,
        orderSaveDirect: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Uploading....",
          buttonDisable: true
        },
      };
    case SAVE_ORDERS_DIRECT_SUCCESS:
      return {
        ...state,
        orderSaveDirect: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false
        },
      };
    case SAVE_ORDERS_DIRECT_FAILURE:
      return {
        ...state,
        orderSaveDirect: {
          data: {},
          loading: true,
          error: action.error,
          loadingButtonContent: "",
          buttonDisable: false
        },
      };
    case SAVE_ORDERS_CART_START:
      return {
        ...state,
        orderSaveCart: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading....",
          buttonDisable: true
        },
      };
    case SAVE_ORDERS_CART_SUCCESS:
      return {
        ...state,
        orderSaveCart: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: null,
          buttonDisable: false
        },
      };
    case SAVE_ORDERS_CART_FAILURE:
      return {
        ...state,
        orderSaveCart: {
          data: {},
          loading: false,
          error: action.error,
          loadingButtonContent: null,
          buttonDisable: false
        },
      };
    case FETCH_ORDERS_LIST_START:
      return {
        ...state,
        orderList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_MORE_ORDERS_LIST_START:
      return state;
    case FETCH_ORDERS_LIST_SUCCESS:
      return {
        ...state,
        orderList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_ORDERS_LIST_FAILURE:
      return {
        ...state,
        orderList: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case FETCH_ORDERS_DETAILS_START:
      return {
        ...state,
        orderDetails: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Uploading....",
          buttonDisable: true
        },
      };
    case FETCH_ORDERS_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetails: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false
        },
      };
    case FETCH_ORDERS_DETAILS_FAILURE:
      return {
        ...state,
        orderDetails: {
          data: {},
          loading: true,
          error: action.error,
          loadingButtonContent: "",
          buttonDisable: false
        },
      };
    case CANCEL_ORDERS_START:
      return {
        ...state,
        cancelOrders: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Uploading....",
          buttonDisable: true
        },
      };
    case CANCEL_ORDERS_SUCCESS:
      return {
        ...state,
        cancelOrders: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false
        },
      };
    case CANCEL_ORDERS_FAILURE:
      return {
        ...state,
        cancelOrders: {
          data: {},
          loading: true,
          error: action.error,
          loadingButtonContent: "",
          buttonDisable: false
        },
      };
    case INVOICE_ORDERS_START:
      return {
        ...state,
        invoiceOrders: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case INVOICE_ORDERS_SUCCESS:
      return {
        ...state,
        invoiceOrders: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case INVOICE_ORDERS_FAILURE:
      return {
        ...state,
        invoiceOrders: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case TRANSACTION_LIST_START:
      return {
        ...state,
        transactionLists: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading....",
          buttonDisable: true
        }
      }
    case TRANSACTION_LIST_SUCCESS:
      return {
        ...state,
        transactionLists: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false
        }
      }
    case TRANSACTION_LIST_FAILURE:
      return {
        ...state,
        transactionLists: {
          data: {},
          loading: true,
          error: action.error,
          loadingButtonContent: "",
          buttonDisable: false
        }
      }
    case MORE_TRANSACTION_LIST_START:
      return state;
    case ORDERS_SEND_CANCEL_REQUEST_START:
      return {
        ...state,
        cancelOrder: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading",
          buttonDisable: true,
        }
      };
    case ORDERS_SEND_CANCEL_REQUEST_SUCCESS:
      return {
        ...state,
        cancelOrder: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: null,
          buttonDisable: false
        }
      };
    case ORDERS_SEND_CANCEL_REQUEST_FAILURE:
      return {
        ...state,
        cancelOrder: {
          data: {},
          loading: false,
          error: action.error,
          loadingButtonContent: null,
          buttonDisable: false,
        }
      };
    case ORDERS_SEND_RETURN_REQUEST_START:
      return {
        ...state,
        returnOrder: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading",
          buttonDisable: true,
        }
      };
    case ORDERS_SEND_RETURN_REQUEST_SUCCESS:
      return {
        ...state,
        returnOrder: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: null,
          buttonDisable: false,
        }
      };
    case ORDERS_SEND_RETURN_REQUEST_FAILURE:
      return {
        ...state,
        returnOrder: {
          data: {},
          loading: false,
          error: action.error,
          loadingButtonContent: null,
          buttonDisable: false,
        }
      }
    case ORDERS_CHECKOUT_START:
      return {
        ...state,
        ordersCheckout: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading",
          buttonDisable: true,
        }
      };
    case ORDERS_CHECKOUT_SUCCESS:
      return {
        ...state,
        ordersCheckout: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: null,
          buttonDisable: false,
        }
      };
    case ORDERS_CHECKOUT_FAILURE:
      return {
        ...state,
        ordersCheckout: {
          data: {},
          loading: false,
          error: action.error,
          loadingButtonContent: null,
          buttonDisable: false,
        }
      };
    case ORDERS_CHECKOUT_STATUS_UPDATE_START:
      return {
        ...state,
        ordersCheckoutStatusUpdate: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Loading",
          buttonDisable: true,
        }
      };
    case ORDERS_CHECKOUT_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        ordersCheckoutStatusUpdate: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: null,
          buttonDisable: false,
        }
      };
    case ORDERS_CHECKOUT_STATUS_UPDATE_FAILURE:
      return {
        ...state,
        ordersCheckoutStatusUpdate: {
          data: {},
          loading: false,
          error: action.error,
          loadingButtonContent: null,
          buttonDisable: false,
        }
      };
    default:
      return state;
  }
}


export default ordersReducer;

