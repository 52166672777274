import React, { useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import CommonCenterLoader from './CommonCenterLoader';
import NoDataFound from './NoDataFound';
import { useNavigate } from 'react-router-dom';
import CustomLazyLoad from './CustomLazyLoad';

const SearchSuggetion = (props) => {
  const navigate = useNavigate();

  const goToProduct = uniqueId => {
    props.setShowSuggetions(false);
    navigate(`/product/${uniqueId}`);
  }

  return (
    <div className="search-suggetion-sec">
      {props.productSuggetions.loading ?
        <CommonCenterLoader />
        : props.productSuggetions.data.products &&
          props.productSuggetions.data.products.length > 0 ?
          props.productSuggetions.data.products.map((product, i) =>
            <div className="search-suggetion" onClick={e => goToProduct(product.product_unique_id)}>
              <CustomLazyLoad
                src={product.file}
                placeholderSrc={window.location.origin + "/images/loading.svg"}
              />
              <p>{product.name}</p>
            </div>
          )
          : <NoDataFound />
      }
    </div>
  );
}

const mapStateToPros = (state) => ({
  productSuggetions: state.product.productSuggetions,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(SearchSuggetion);