import React, { useState, useEffect } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Landing.css";
import { connect } from "react-redux";
import Slider from "react-slick";
import { withTranslation, useTranslation } from "react-multi-lang";
import { fetchBannersListStart } from "../store/actions/HomePageAction";
import BannerListLoader from "../Helper/BannerListLoader";
import CustomLazyLoad from "../Helper/CustomLazyLoad";

const HomeBannerIndex = (props) => {
  const t = useTranslation();

  const { bannerList } = props;

  useEffect(() => {
    props.dispatch(fetchBannersListStart());
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <>
      <div className="home-banner-sec">
        {bannerList.loading ? (
          <BannerListLoader />
        ) : (
          <Container>
            {bannerList.data.banners && bannerList.data.banners.length > 0 ? (
              <div className="home-banner-content">
                {/* <Carousel showStatus={false} showArrows={false} showIndicators={false}> */}

                <Slider {...settings}>
                  {bannerList.data.banners.map((banner, index) => (
                    <div>
                      <Row
                        className="align-items-center new-home-banner-sec"
                        key={index}
                      >
                        <Col md={6} className="resp-mrg-btm-xs">
                          <div className="home-banner-info">
                            <h5>{t("clothing")}</h5>
                            <h1>{banner.title}</h1>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: banner.description,
                              }}
                            ></p>
                            <div className="default-btn-sec">
                              <a
                                href={banner.link}
                                target="_blank"
                                className="default-btn"
                              >
                                {t("shop_now")}
                              </a>
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="home-banner-img-sec">
                            <CustomLazyLoad
                              src={banner.picture}
                              placeholderSrc={
                                window.location.origin +
                                "/images/transparent.png"
                              }
                              classes="home-banner-img"
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </Slider>

                {/* </Carousel> */}
              </div>
            ) : (
              <Slider {...settings}>
                <div>
                  <Row className="align-items-center">
                    <Col md={6} className="resp-mrg-btm-xs">
                      <div className="home-banner-info">
                        <h5>{t("cosmetics")}</h5>
                        <h1>{t("big_discounts")}</h1>
                        <p>
                          {t("home_banner_content")}
                        </p>
                        <div className="default-btn-sec">
                          <Link to="/shop" className="default-btn">
                            {t("shop_now")}
                          </Link>
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="home-banner-img-sec">
                        <CustomLazyLoad
                          src={
                            window.location.origin +
                            "/images/landing-page/banner-img-1.png"
                          }
                          placeholderSrc={
                            window.location.origin + "/images/transparent.png"
                          }
                          classes="home-banner-img"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Slider>
            )}
          </Container>
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  bannerList: state.homepage.bannerList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(HomeBannerIndex));
