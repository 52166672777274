import React from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import { useTranslation, withTranslation } from "react-multi-lang";

const FooterIndex = () => {
  const t = useTranslation();

  return (
    <>
      <footer className="footer-sec">
        <div className="main-footer-sec">
          <Container>
            <Row className="justify-content-center">
              <Col md={4} xl={4} lg={4} className="resp-mrg-btm-md">
                <div className="footer-company-widget">
                  <div className="footer-log-sec">
                    <Image
                      className="footer-logo"
                      src={configuration.get("configData.site_logo")}
                      type="image/png"
                    />
                  </div>
                  <div className="footer-company-info">
                    <p>{configuration.get("configData.tag_name")}</p>
                  </div>
                </div>
              </Col>
              {/* <Col md={2} xl={2} lg={2} xs={6}>
                <div className="footer-widget">
                  <h4 className="text-uppercase">{t("about")}</h4>
                  <ul className="list-unstyled footer-link">
                    {configuration
                      .get("configData.footer_pages1")
                      .map((static_page, index) => (
                        <li
                          as="li"
                          key={"sp-" + static_page.static_page_unique_id}
                        >
                          <Link
                            to={`/page/${static_page.static_page_unique_id}`}
                            key={static_page.static_page_unique_id}
                          >
                            {static_page.title}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </Col>
              <Col md={2} xl={2} lg={2} xs={6}>
                <div className="footer-widget">
                  <h4>{t("help")}</h4>
                  <ul className="list-unstyled footer-link">
                    {configuration
                      .get("configData.footer_pages2")
                      .map((static_page, index) => (
                        <li
                          as="li"
                          key={"sp-" + static_page.static_page_unique_id}
                        >
                          <Link
                            to={`/page/${static_page.static_page_unique_id}`}
                            key={static_page.static_page_unique_id}
                          >
                            {static_page.title}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </Col> */}
              <Col md={4} xl={4} lg={4} xs={6}>
                <div className="footer-widget">
                  <h4>{t("policy")}</h4>
                  <ul className="list-unstyled footer-link">
                    <li>
                      <Link to="/page/terms-of-service">{t("terms_of_use")}</Link>
                    </li>
                    <li>
                      <Link to="/page/privacy-policy">{t("privacy_policy")}</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={4} xl={4} lg={4} xs={12}>
                <div className="footer-widget">
                  <h4>{t("socials")}</h4>
                  <ul className="list-unstyled social-link">
                    {configuration.get("configData.instagram_link") ? (
                      <li>
                        <a
                          href={configuration.get("configData.instagram_link")}
                          target="_blank"
                        >
                          <Image
                            className="social-link-icon"
                            src={
                              window.location.origin +
                              "/images/social-icon/instagram-icon.svg"
                            }
                            type="image/png"
                          />
                        </a>
                      </li>
                    ) : null}

                    {configuration.get("configData.twitter_link") ? (
                      <li>
                        <a
                          href={configuration.get("configData.twitter_link")}
                          target="_blank"
                        >
                          <Image
                            className="social-link-icon"
                            src={
                              window.location.origin +
                              "/images/social-icon/twitter-icon.svg"
                            }
                            type="image/png"
                          />
                        </a>
                      </li>
                    ) : null}

                    {configuration.get("configData.linkedin_link") ? (
                      <li>
                        <a
                          href={configuration.get("configData.linkedin_link")}
                          target="_blank"
                        >
                          <Image
                            className="social-link-icon"
                            src={
                              window.location.origin +
                              "/images/social-icon/linkedin-icon.svg"
                            }
                            type="image/png"
                          />
                        </a>
                      </li>
                    ) : null}

                    {configuration.get("configData.facebook_link") ? (
                      <li>
                        <a
                          href={configuration.get("configData.facebook_link")}
                          target="_blank"
                        >
                          <Image
                            className="social-link-icon"
                            src={
                              window.location.origin +
                              "/images/social-icon/facebook-icon.svg"
                            }
                            type="image/png"
                          />
                        </a>
                      </li>
                    ) : null}

                    {configuration.get("configData.youtube_link") ? (
                      <li>
                        <a
                          href={configuration.get("configData.youtube_link")}
                          target="_blank"
                        >
                          <Image
                            className="social-link-icon"
                            src={
                              window.location.origin +
                              "/images/social-icon/youtube-icon.svg"
                            }
                            type="image/png"
                          />
                        </a>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6} xl={6} lg={6} xs={12}>
              <p>{configuration.get("configData.copyright_content")}</p>
              </Col>
            </Row>
          </Container>
          
        </div>
        {/* <div className="sub-footer-sec">
          <Container>
            <Row>
              <Col md={12}>
                <div className="sub-footer-box">
                  <div className="sub-footer-info">
                    <h3>{t("subscribe_to_our_newsletter_wedontspam")}</h3>
                    <ul className="list-unstyled sub-footer-link">
                      <li>
                        <Link to="#">{t("product_recommendation")}</Link>
                      </li>
                      <li>
                        <Link to="#">{t("industry_updates")}</Link>
                      </li>
                      <li>
                        <Link to="#">{t("discounts_offers")}</Link>
                      </li>
                      <li>
                        <Link to="#">{t("learn_about_cannabis")}</Link>
                      </li>
                      <li>
                        <Link to="#">{t("product_adoption_ideas")}</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="sub-footer-btn-sec">
                    <Button className="subscribe-btn">{t("subscribe")}</Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div> */}
      </footer>
    </>
  );
};

export default withTranslation(FooterIndex);
