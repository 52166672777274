import React from 'react';
import Countdown from 'react-countdown';
import { getErrorNotificationMessage } from '../Helper/ToastNotification';


const CounterTimer = (props) => {
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            getErrorNotificationMessage("Payment request has timed out. Please try again later.");
            props.setPaymentStarted(false);
            // return <></>;
        } else {
            // Render a countdown
            return <span>{(minutes < 10 ? "0" : "") + minutes}:{(seconds < 10 ? "0" : "") + seconds} seconds</span>;
        }
    };
    return (
        <Countdown
            date={Date.now() + 300000}
            renderer={renderer}
        />
    )
}

export default React.memo(CounterTimer);