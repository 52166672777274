import React, { useContext, useEffect, useRef, useState } from "react";
// import { createBrowserHistory as createHistory } from "history";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import EmptyLayout from "../layouts/EmptyLayout";
import CheckoutLayout from "../layouts/CheckoutLayout";
import MainLayout from "../layouts/MainLayout";
import LandingPageIndex from "../landingPage/LandingPageIndex";
import CartIndex from "../Cart/CartIndex";
import ProductSearchIndex from "../Product/ProductSearchIndex";
import LoginIndex from "../Auth/LoginIndex";
import SingleProductIndex from "../Product/SingleProductIndex";
import ResetPassword from "../Auth/ResetPassword";
import RegisterIndex from "../Auth/RegisterIndex";
import PrivacyPolicy from "../Static/PrivacyPolicy";
import ProfileIndex from "../Profile/ProfileIndex";
import MyOrder from "../Profile/MyOrder";
import ManageAddress from "../Profile/ManageAddress";
import ChangePassword from "../Profile/ChangePassword";
import DeleteAccount from "../Profile/DeleteAccount";
import TwoStepAuthentication from "../Profile/TwoStepAuthentication";
import PaymentMethod from "../Profile/PaymentMethod";
import ProfileWallet from "../Profile/ProfileWallet";
import WalletAddMoney from "../Profile/WalletAddMoney";
import WalletWithdrawMoney from "../Profile/WalletWithdrawMoney";
import ContactUs from "../Contact/ContactUs";
import CheckOutOldIndex from "../Cart/CheckOutOldIndex";
import AboutUs from "../Contact/AboutUs";
import OrderPlaced from "../Cart/OrderPlaced";
import MobileProfile from "../Profile/MobileProfile";
import WishListIndex from "../WishList/WishListIndex";
import VerificationIndex from "../Verification/VerificationIndex";
import AuthenticationIndex from "../Verification/AuthenticationIndex";
import StaticPage from "../Static/StaticPage";
import {
  setTranslations,
  setDefaultLanguage,
  translate,
  setLanguage,
} from "react-multi-lang";
import en from "../translations/en.json";
import es from "../translations/es.json";
import LogoutIndex from "../Auth/LogoutIndex";
import ForgotPassword from "../Auth/ForgotPassword";
import { ToastContainer } from "react-toastify";
import PageNotFound from "../Helper/PageNotFound";
import Withdraw from "../Profile/Withdraw";
import CheckoutAddress from "../Cart/CheckoutAddress";
import OrderSummary from "../Cart/OrderSummary";
import CheckoutPayment from "../Cart/CheckoutPayment";
import OrderTrackingIndex from "../Profile/OrderTrackingIndex";
import SingleTransaction from "../Transaction/SingleTransaction";
import OrderTransactions from "../Profile/OrderTransactions";

setTranslations({ en, es });

// const history = createHistory();
const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Layout screenProps={ScreenProps} {...rest}>
    <Component />
  </Layout>
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => {
  let userId = localStorage.getItem("userId");
  let accessToken = localStorage.getItem("accessToken");
  let userLoginStatus = localStorage.getItem("userLoginStatus");
  let authentication =
    userId && accessToken && userLoginStatus == "true" ? true : false;
  return authentication === true ? (
    <Layout screenProps={ScreenProps}>
      <Component authRoute={true} />
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );
};

const App = () => {
  setLanguage("en");
  // let userId = localStorage.getItem("userId");
  // let accessToken = localStorage.getItem("accessToken");
  // let userLoginStatus = localStorage.getItem("userLoginStatus");

  const [loading, setLoading] = useState(true);

  const [configLoading, setConfigLoading] = useState(true);

  useEffect(() => {
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);
  }, []);

  // const [authentication, setAuthntication] = useState(
  //   userId && accessToken && userLoginStatus == "true" ? true : false
  // );

  // history.listen((location, action) => {
  //   userId = localStorage.getItem("userId");
  //   accessToken = localStorage.getItem("accessToken");
  //   userLoginStatus = localStorage.getItem("userLoginStatus");
  //   setLoading(true);
  //   setAuthntication(
  //     userId && accessToken && userLoginStatus == "true" ? true : false
  //   );
  //   document.body.scrollTop = 0;
  // });

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{configuration.get("configData.site_name")}</title>
        <link
          rel="icon"
          type="image/png"
          href={configuration.get("configData.site_icon")}
        />
        <script src={configuration.get("configData.head_scripts")}></script>
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route
            path={"/"}
            element={
              <AppRoute component={LandingPageIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/cart"}
            element={<AppRoute component={CartIndex} layout={MainLayout} />}
          />
          <Route
            path={"/shop"}
            element={
              <AppRoute component={ProductSearchIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/shop/:search"}
            element={
              <AppRoute component={ProductSearchIndex} layout={MainLayout} />
            }
          />
          <Route
            exact
            path={"/product/:productUniqueId"}
            element={
              <AppRoute component={SingleProductIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/login"}
            element={<AppRoute component={LoginIndex} layout={EmptyLayout} />}
          />
          <Route
            path={"/forgot-password"}
            element={
              <AppRoute component={ForgotPassword} layout={EmptyLayout} />
            }
          />
          <Route
            path={"/reset-password/:token"}
            element={
              <AppRoute component={ResetPassword} layout={EmptyLayout} />
            }
          />
          <Route
            path={"/register"}
            element={
              <AppRoute component={RegisterIndex} layout={EmptyLayout} />
            }
          />
          <Route
            path={"/privacy-policy"}
            element={<AppRoute component={PrivacyPolicy} layout={MainLayout} />}
          />
          <Route
            path={"/profile"}
            element={
              <PrivateRoute component={ProfileIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/my-order"}
            element={<PrivateRoute component={MyOrder} layout={MainLayout} />}
          />
          <Route
            path={"/my-order/:status"}
            element={<PrivateRoute component={MyOrder} layout={MainLayout} />}
          />
          <Route
            path={"/order-transactions"}
            element={<PrivateRoute component={OrderTransactions} layout={MainLayout} />}
          />
          <Route
            path={"/manage-address"}
            element={
              <PrivateRoute component={ManageAddress} layout={MainLayout} />
            }
          />
          <Route
            path={"/change-password"}
            element={
              <PrivateRoute component={ChangePassword} layout={MainLayout} />
            }
          />
          <Route
            path={"/delete-account"}
            element={
              <PrivateRoute component={DeleteAccount} layout={MainLayout} />
            }
          />
          <Route
            path={"/two-step-authentication"}
            element={
              <PrivateRoute
                component={TwoStepAuthentication}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/payment-method"}
            element={
              <PrivateRoute component={PaymentMethod} layout={MainLayout} />
            }
          />
          <Route
            path={"/profile-wallet"}
            element={
              <PrivateRoute component={ProfileWallet} layout={MainLayout} />
            }
          />
          <Route
            path={"/withdraw"}
            element={<PrivateRoute component={Withdraw} layout={MainLayout} />}
          />
          <Route
            path={"/wallet-add-money"}
            element={
              <PrivateRoute component={WalletAddMoney} layout={MainLayout} />
            }
          />
          <Route
            path={"/order-tracking/:orderUniqueId"}
            element={
              <PrivateRoute component={OrderTrackingIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/single-transaction/:orderUniqueId"}
            element={
              <PrivateRoute component={SingleTransaction} layout={MainLayout} />
            }
          />
          <Route
            path={"/wallet-withdraw-money"}
            element={
              <PrivateRoute
                component={WalletWithdrawMoney}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/contact-us"}
            element={<AppRoute component={ContactUs} layout={MainLayout} />}
          />
          <Route
            path={"/checkout-old"}
            element={
              <PrivateRoute component={CheckOutOldIndex} layout={EmptyLayout} />
            }
          />
          <Route
            path={"/checkout-address"}
            element={
              <PrivateRoute component={CheckoutAddress} layout={CheckoutLayout} />
            }
          />
          <Route
            path={"/order-summary"}
            element={
              <PrivateRoute component={OrderSummary} layout={CheckoutLayout} />
            }
          />
          <Route
            path={"/checkout-payment"}
            element={
              <PrivateRoute component={CheckoutPayment} layout={CheckoutLayout} />
            }
          />
          <Route
            path={"/about-us"}
            element={<AppRoute component={AboutUs} layout={MainLayout} />}
          />
          <Route
            path={"/page/:static_page_unique_id"}
            element={<AppRoute component={StaticPage} layout={MainLayout} />}
          />
          <Route
            path={"/order-placed"}
            element={
              <PrivateRoute component={OrderPlaced} layout={MainLayout} />
            }
          />
          <Route
            path={"/mobile-profile"}
            element={
              <PrivateRoute component={MobileProfile} layout={MainLayout} />
            }
          />
          <Route
            path={"/wishlist"}
            element={
              <PrivateRoute component={WishListIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/verification"}
            element={
              <AppRoute component={AuthenticationIndex} layout={EmptyLayout} />
            }
          />
          <Route
            path={"/register-verification"}
            element={
              <AppRoute component={VerificationIndex} layout={EmptyLayout} />
            }
          />
          <Route
            path={"/logout"}
            element={<PrivateRoute component={LogoutIndex} layout={EmptyLayout} />}
          />
          <Route
            path={"/tracking/:orderUniqueId"}
            element={<PrivateRoute component={OrderTrackingIndex} layout={EmptyLayout} />}
          />
          <Route
            path="*"
            element={<AppRoute component={PageNotFound} layout={MainLayout} />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
