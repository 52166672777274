import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../Helper/ToastNotification";
import {
  SAVE_DELIVERY_ADDRESSES_START,
  FETCH_DELIVERY_ADDRESSES_LIST_START,
  MAKE_DEFAULT_DELIVERY_ADDRESSES_START,
  DELETE_DELIVERY_ADDRESSES_START,
  FETCH_SINGLE_DELIVERY_ADDRESS_START,
} from "../actions/ActionConstant";

import {
  saveDeliveryAddressesSuccess,
  saveDeliveryAddressesFailure,
  fetchDeliveryAddressesListSuccess,
  fetchDeliveryAddressesListFailure,
  makeDefaultDeliveryAddressesSuccess,
  makeDefaultDeliveryAddressesFailure,
  deleteDeliveryAddressesSuccess,
  deleteDeliveryAddressesFailure,
  fetchSingleDeliveryAddressSuccess,
  fetchSingleDeliveryAddressFailure,
} from "../actions/DeliveryAction";

function* saveDeliveryAddressesAPI(action) {
  try {
    const response = yield api.postMethod("delivery_addresses_save", action.data);

    if (response.data.success) {
      yield put(saveDeliveryAddressesSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.message);
    } else {
      yield put(saveDeliveryAddressesFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(saveDeliveryAddressesFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }

}

function* fetchDeliveryAddressesListAPI() {
  try {
    const response = yield api.postMethod("delivery_addresses_list");

    if (response.data.success) {
      yield put(fetchDeliveryAddressesListSuccess(response.data.data));
    } else {
      yield put(fetchDeliveryAddressesListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchDeliveryAddressesListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* makeDefaultDeliveryAddressesAPI(action) {
  try {
    const response = yield api.postMethod("delivery_addresses_make_default", action.data);

    if (response.data.success) {
      yield put(makeDefaultDeliveryAddressesSuccess(response.data.data));
    } else {
      yield put(makeDefaultDeliveryAddressesFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(makeDefaultDeliveryAddressesFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* deleteDeliveryAddressesAPI(action) {
  try {
    const response = yield api.postMethod("delivery_addresses_delete", action.data);

    if (response.data.success) {
      yield put(deleteDeliveryAddressesSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
      let deliveryData = yield select((state) => state.delivery.deliveryAddressesList.data);
      deliveryData = {
        ...deliveryData,
        delivery_addresses: deliveryData.delivery_addresses.filter(add => add.delivery_address_id !== action.data.delivery_address_id),
      }
      yield put(fetchDeliveryAddressesListSuccess(deliveryData));
    } else {
      yield put(deleteDeliveryAddressesFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(deleteDeliveryAddressesFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchSingleDeliveryAddressAPI(action) {
  try {
    const response = yield api.postMethod("delivery_addresses_view", action.data);

    if (response.data.success) {
      yield put(fetchSingleDeliveryAddressSuccess(response.data.data));
    } else {
      yield put(fetchSingleDeliveryAddressFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchSingleDeliveryAddressFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}


export default function* pageSaga() {
  yield all([
    yield takeLatest(SAVE_DELIVERY_ADDRESSES_START, saveDeliveryAddressesAPI),
    yield takeLatest(FETCH_DELIVERY_ADDRESSES_LIST_START, fetchDeliveryAddressesListAPI),
    yield takeLatest(MAKE_DEFAULT_DELIVERY_ADDRESSES_START, makeDefaultDeliveryAddressesAPI),
    yield takeLatest(DELETE_DELIVERY_ADDRESSES_START, deleteDeliveryAddressesAPI),
    yield takeLatest(FETCH_SINGLE_DELIVERY_ADDRESS_START, fetchSingleDeliveryAddressAPI),
  ]);
}
