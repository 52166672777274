import React, { useState, useEffect } from "react";
import { Row, Col, Container, Image, Form, Button } from "react-bootstrap";
import ProfileSideBar from "./ProfileSideBar";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { deleteAccountStart } from "../store/actions/UserAction";
import { withTranslation, useTranslation } from "react-multi-lang";
import { useNavigate } from "react-router-dom";

const DeleteAccount = (props) => {
  const t = useTranslation();

  const [agree, setAgree] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !skipRender &&
      !props.deleteAcc.loading &&
      Object.keys(props.deleteAcc.data).length > 0
    ) {
      navigate("/logout");
    }
    setSkipRender(false);
  }, [props.deleteAcc]);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("password_is_required"))
      .matches(/^(?=.*[a-zA-Z-0-9])/, t("space_values_not_valid"))
      .min(6, t("must_contain_6_characters")),
  });

  const passwordValidation = (value) => {
    const trimmedValue = value.replaceAll(" ", "");
    if (trimmedValue !== value) return t("should_not_contain_empty_space");
    return "";
  };

  const handleSubmit = (values) => {
    props.dispatch(deleteAccountStart(values));
  };

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col xl={3} md={12} lg={4}>
              <div className="new-mobile-section">
                <ProfileSideBar />
              </div>
            </Col>
            <Col xl={9} md={12} lg={8}>
              <div className="profile-details-sec">
                <div className="profile-details-title">
                  <h4>{t("delete_account")}</h4>
                  <p>{t("will_see_you_soon")}</p>
                </div>
                <div className="account-delete-sec">
                  <h4>
                    <span>{t("caution")}</span> {t("caution_delete_account_text")}
                  </h4>
                  <div className="login-form">
                    <Formik
                      initialValues={{
                        password: "",
                      }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => handleSubmit(values)}
                    >
                      {({
                        touched,
                        errors,
                        isSubmitting,
                        setFieldValue,
                        values,
                      }) => (
                        <FORM>
                          <Row>
                            <Col md={6}>
                              {props.profile.data.login_by == "manual" && (
                                <Form.Group className="mb-3">
                                  <Form.Label>{t("your_password")}</Form.Label>
                                  <Field
                                    type="password"
                                    className="form-control"
                                    placeholder={t("enter_your_password")}
                                    name="password"
                                    value={values.password}
                                    validate={passwordValidation}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="password"
                                    className="text-danger text-right"
                                  />
                                </Form.Group>
                              )}
                              <Form.Group
                                className="mb-3 form-check-custom"
                                controlId="formBasicCheckbox"
                              >
                                <Form.Check
                                  type="checkbox"
                                  label={t(
                                    "i_understand_and_wish_to_delete_my_account"
                                  )}
                                  required
                                  name="acceptTerms"
                                  checked={agree}
                                  onChange={() => setAgree(!agree)}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <div className="login-btn default-btn-sec"></div>

                          <div className="default-btn-sec">
                            <Button
                              className="default-btn"
                              type="submit"
                              disabled={
                                props.deleteAcc.buttonDisable ||
                                !agree
                              }
                            >
                              {props.deleteAcc.loadingButtonContent !== null
                                ? props.deleteAcc.loadingButtonContent
                                : t("submit")}
                            </Button>
                          </div>
                        </FORM>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  deleteAcc: state.users.deleteAccount,
  profile: state.users.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(DeleteAccount));
