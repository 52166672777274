import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../Helper/ToastNotification";
import {
  SAVE_CART_DETAILS_START,
  FETCH_CART_LIST_START,
  DELETE_CART_DETAILS_START,
  FETCH_GUEST_CART_LIST_START,
  UPDATE_GUEST_CART_LIST_START,
} from "../actions/ActionConstant";

import {
  saveCartDetailsSuccess,
  saveCartDetailsFailure,
  fetchCartListSuccess,
  fetchCartListFailure,
  fetchCartListStart,
  deleteCartDetailsSuccess,
  deleteCartDetailsFailure,
  fetchGuestCartListStart,
  fetchGuestCartListFailure,
  fetchGuestCartListSuccess,
} from "../actions/CartsAction";
import { fetchSingleProductSuccess, forceProductListSuccess } from "../actions/ProductAction";
import { fetchUserDetailsSuccess } from "../actions/UserAction";

function* saveCartDetailsAPI(action) {
  try {
    const response = yield api.postMethod("carts_save", action.data);
    if (response.data.success) {
      yield put(saveCartDetailsSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.message);
      const profileData = yield select((state) => state.users.profile.data);
      yield put(fetchUserDetailsSuccess({
        ...profileData,
        cart_count: response.data.data.cart_count,
      }));
      const singleProductData = yield select((state) => state.product.singleProduct.data);
      if (Object.keys(singleProductData).length > 0) {
        yield put(fetchSingleProductSuccess({
          ...singleProductData,
          product: {
            ...singleProductData.product,
            is_carted: 1,
          }
        }));
      }
      const productListData = yield select((state) => state.product.productList.data);
      if (Object.keys(productListData).length > 0) {
        yield put(forceProductListSuccess({
          ...productListData,
          products: productListData.products.map((product) => product.product_id === action.data.product_id
            ? { ...product, is_carted: 1 }
            : product),
        }));
      }
      const cartListData = yield select((state) => state.carts.cartList.data);
      if (Object.keys(cartListData).length > 0) {
        yield put(fetchCartListSuccess({
          ...cartListData,
          carts: cartListData.carts.map((product) => product.product_id === action.data.product_id
            ? response.data.data.cart
            : product),
          user_cart_amount: response.data.data.user_cart_amount,
        }));
      }
    } else {
      yield put(saveCartDetailsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(saveCartDetailsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }

}

function* fetchCartListAPI() {
  try {
    const response = yield api.postMethod("carts_list");

    if (response.data.success) {
      yield put(fetchCartListSuccess(response.data.data));
    } else {
      yield put(fetchCartListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchCartListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}


function* deleteCartDetailsAPI(action) {
  try {
    const response = yield api.postMethod("carts_delete", action.data);

    if (response.data.success) {
      yield call(getSuccessNotificationMessage, response.message);
      yield put(deleteCartDetailsSuccess(response.data.data));
      const profileData = yield select((state) => state.users.profile.data);
      yield put(fetchUserDetailsSuccess({
        ...profileData,
        cart_count: response.data.data.cart_count,
      }));
      const cartListData = yield select((state) => state.carts.cartList.data);
      if (Object.keys(cartListData).length > 0) {
        const newCartListData = {
          ...cartListData,
          carts: cartListData.carts.filter(cart => cart.product.product_id != action.data.product_id),
        };
        yield put(fetchCartListSuccess(newCartListData));
      }
      const productListData = yield select((state) => state.product.productList.data);
      if (Object.keys(productListData).length > 0) {
        yield put(forceProductListSuccess({
          ...productListData,
          products: productListData.products.map((product) => product.product_id === action.data.product_id
            ? { ...product, is_carted: 0 }
            : product),
        }));
      }
    } else {
      yield put(deleteCartDetailsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(deleteCartDetailsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchGuestCartListAPI(action) {
  console.log(action);
  try {
    const response = yield api.postMethod("guest_carts_list",action.data);

    if (response.data.success) {
      yield put(fetchGuestCartListSuccess(response.data.data));
    } else {
      yield put(fetchGuestCartListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchGuestCartListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

export default function* pageSaga() {
  yield all([
    yield takeLatest(SAVE_CART_DETAILS_START, saveCartDetailsAPI),
    yield takeLatest(FETCH_CART_LIST_START, fetchCartListAPI),
    yield takeLatest(DELETE_CART_DETAILS_START, deleteCartDetailsAPI),
    yield takeLatest(FETCH_GUEST_CART_LIST_START, fetchGuestCartListAPI),
    yield takeLatest(UPDATE_GUEST_CART_LIST_START, fetchGuestCartListAPI),
  ]);
}