import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Accordion,
  Image,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { fetchDeliveryAddressesListStart } from "../store/actions/DeliveryAction";
import "./NewCheckOut.css";
import { connect } from "react-redux";
import SomethingWrong from "../Helper/SomethingWrong";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Helper/NoDataFound";
import { fetchCartListStart } from "../store/actions/CartsAction";
import ReactStars from "react-rating-stars-component";
import AddNewAddressPopUpModal from "./AddNewAddressPopUpModal";
import { useTranslation, withTranslation } from "react-multi-lang";

const CheckoutAddress = (props) => {
  const navigate = useNavigate();

  const [skipRender, setSkipRender] = useState(true);
  const t = useTranslation();
  const [showContent, setShowContent] = useState(true);
  const [writeReview, setWriteReview] = useState(false);
  

  const closeWriteReviewModal = () => {
    setWriteReview(false);
  };

  useEffect(() => {
    props.dispatch(fetchDeliveryAddressesListStart());
    props.dispatch(fetchCartListStart());
  }, []);

  useEffect(() => {
    if (
      (!skipRender &&
        !props.deliveryAddressesList.loading &&
        (props.deliveryAddressesList.error !== false ||
          !props.deliveryAddressesList.data.delivery_addresses)) ||
      (!props.cartList.loading &&
        (props.cartList.error !== false ||
          !props.cartList.data.user_cart_amount))
    ) {
      setShowContent(false);
    }
    setSkipRender(false);
  }, [props.deliveryAddressesList, props.cartList]);

  const confirmAddress = (addressUniqueId) => {
    localStorage.setItem("addessUniqueId", addressUniqueId);
    navigate("/order-summary");
  };

  return (
    <>
      <div className="newcheckout-sec">
        {showContent ? (
          <Container>
            <Row>
              <Col xl={8} md={12} className="resp-mrg-btm-md">
                <div className="newcheckout-header-sec">
                  <ul className="list-unstyled new-checkout-progress-bar">
                    <li className="step1">
                      <div className="newcheckout-flex active">
                        <div className="count-no">1</div>
                        <h6>{t("address")}</h6>
                      </div>
                    </li>
                    <li className="step2">
                      <div className="newcheckout-flex">
                        <div className="count-no">2</div>
                        <h6>{t("order_summary")}</h6>
                      </div>
                    </li>
                    <li className="step3">
                      <div className="newcheckout-flex">
                        <div className="count-no">3</div>
                        <h6>{t("payment")}</h6>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="newcheckout-body-wrapper">
                  <div className="step-1">
                    <div className="address-label-box">
                      {props.deliveryAddressesList.loading ? (
                        <Skeleton count={2} height={200} />
                      ) : props.deliveryAddressesList.data.delivery_addresses
                          .length > 0 ? (
                        props.deliveryAddressesList.data.delivery_addresses.map(
                          (address, i) => (
                            <div className="address-radio-btn" key={i}>
                              <label onClick={(e) => e.preventDefault()}>
                                <div className="saved-address-box">
                                  <div className="address-details">
                                    <h4>{address.name}</h4>
                                    <p className="address-block">{address.address}</p>
                                    <p className="address-block">
                                      {address.city}{", "}
                                      {address.state}{", "}
                                      {address.pincode}
                                      {/* {"\n"}
                                      {address.landmark}
                                      {"\n"}
                                      {address.contact_number} */}
                                    </p>
                                    <p className="address-block">{address.contact_number}</p>
                                  </div>
                                  <div className="address-btn">
                                    <Button
                                      onClick={() =>
                                        confirmAddress(
                                          address.delivery_address_unique_id
                                        )
                                      }
                                      className="address-button"
                                    >
                                      {t("use_address")}
                                    </Button>
                                  </div>
                                </div>
                              </label>
                            </div>
                          )
                        )
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                    <Link to="#" className="add-new-address-link" onClick={() => setWriteReview(true)}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
                      </svg>
                      {t("add_address")}
                    </Link>
                    
                    <h3></h3>
                  </div>
                </div>
              </Col>
              <Col xl={4} md={12}>
                <div className="checkout-box">
                  <div className="cart-box">
                    <div className="cart-header-sec">
                      <h4>{t("price_details")}</h4>
                    </div>
                    {props.cartList.loading ? (
                      <Skeleton height={200} />
                    ) : (
                      <div className="cart-body-sec">
                        <div className="cart-summary-info">
                          <div className="cart-summary-header">
                            <p>
                              {t("cart_total")}{" "}
                              <span>
                                {
                                  props.cartList.data.user_cart_amount
                                    .cart_total_amount
                                }
                              </span>
                            </p>
                            <p>
                              {t("discount")}{" "}
                              <span>
                                -
                                {
                                  props.cartList.data.user_cart_amount
                                    .cart_discount_amount
                                }
                              </span>
                            </p>
                          </div>
                          <div className="cart-summary-total-amount">
                            <h5>
                              {t("order_total")}{" "}
                              <span>
                                {
                                  props.cartList.data.user_cart_amount
                                    .cart_checkout_amount
                                }
                              </span>
                            </h5>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            {writeReview && (
              <AddNewAddressPopUpModal
                writeReview={writeReview}
                closeWriteReviewModal={closeWriteReviewModal}
              />
            )}
          </Container>
        ) : (
          <div className="text-center">
            <SomethingWrong />
            <Button onClick={() => navigate("/")}>{t("go_home")}</Button>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  cartList: state.carts.cartList,
  deliveryAddressesList: state.delivery.deliveryAddressesList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(CheckoutAddress));
