import { Link } from "react-router-dom";
import React, { useState, useCallback } from 'react';
import { Form, Button, Image, Modal } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import { withTranslation, useTranslation } from "react-multi-lang";
import { useDropzone } from "react-dropzone";
import { connect } from "react-redux";
import {
  saveReviewsFilesStart,
	deleteReviewsFilesStart,
	saveReviewsStart,
} from "../store/actions/ReviewsAction";
import { useEffect } from "react";

const WriteReviewModal = (props) => {

	const [skipRender, setSkipRender] = useState(true);
  const t = useTranslation();

	const [reviewData, setReviewData] = useState({
		image: "",
		star: 0,
		review: "",
		product_id: props.singleProduct.data.product.product_id,
		review_file_ids: [],
	});

	const [reviewFiles, setReviewFiles] = useState([]);

	const onDrop = useCallback(acceptedFiles => {
		// Do something with the files
		// setReviewData({
		// 	...reviewData,
		// 	image: acceptedFiles.map(file => Object.assign(file, {
		// 		preview: URL.createObjectURL(file)
		// 	}))
		// });
		props.dispatch(saveReviewsFilesStart({ file: acceptedFiles[0]}));
	}, []);

	const ratingChanged = (newRating) => {
		setReviewData({
			...reviewData,
			star: newRating
		});
	};

	useEffect(() => {
		if (!skipRender && !props.reviewsSaveFiles.loading && 
			Object.keys(props.reviewsSaveFiles.data).length > 0) {
				let review_file_ids = [];
        review_file_ids.push(props.reviewsSaveFiles.data.review_file_id);
				setReviewFiles([
					...reviewFiles,
					props.reviewsSaveFiles.data
				])
				setReviewData({
					...reviewData,
					review_file_ids: JSON.stringify(review_file_ids),
				})
		}
		setSkipRender(false)
	}, [props.reviewsSaveFiles]);

	useEffect(() => {
		if (!skipRender && !props.reviewsDeleteFiles.loading && 
			Object.keys(props.reviewsDeleteFiles.data).length > 0) {
				setReviewFiles([])
				setReviewData({
					...reviewData,
					review_file_ids: [],
				})
		}
		setSkipRender(false)
	}, [props.reviewsDeleteFiles]);

	useEffect(() => {
		if (!skipRender && !props.reviewsSave.loading && 
			Object.keys(props.reviewsSave.data).length > 0) {
				props.closeWriteReviewModal()
				setReviewFiles([])
				setReviewData({
					image: "",
					star: 0,
					review: "",
					product_id: props.singleProduct.data.product.product_id,
					review_file_ids: [],
				})
		}
		setSkipRender(false)
	}, [props.reviewsSave]);

	const saveReview = e => {
		e.preventDefault();
		props.dispatch(saveReviewsStart(reviewData));
	}

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

	return (
		<>
			<Modal
				className="modal-dialog-center write-review-modal"
				size="md"
				centered
				show={props.writeReview}
				onHide={props.closeWriteReviewModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>{t("write_review")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={saveReview} className="write-review-form">
						<ReactStars
							count={5}
							onChange={ratingChanged}
							size={24}
							isHalf={true}
							emptyIcon={<i class="far fa-star"></i>}
							halfIcon={<i class="fas fa-star-half-alt"></i>}
							fullIcon={<i class="fas fa-star"></i>}
							activeColor="#ffd700"
						/>
						<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
							<Form.Label>Review</Form.Label>
							<Form.Control 
								as="textarea" 
								rows={3} 
								name="review" 
								onChange={(e) => setReviewData({
									...reviewData,
									review: e.target.value
								})} 
							/>
						</Form.Group>
						<div className="write-review-dropzone">
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								{
									isDragActive ?
										<p>{t("drop_files")}</p> :
										<p>{t("drag_drop_files")}</p>
								}
							</div>
						</div>
						{reviewFiles.length > 0 && (
							reviewFiles.map((preview) => (
							<div 
								className="review-preview" 
								onClick={() => props.dispatch(deleteReviewsFilesStart({ 
									review_file_id: preview.review_file_id
								}))}
							>
								<img
									src={preview.file}
									className="review-preview-img"
									// onLoad={() => { URL.revokeObjectURL(reviewData.image[0].preview) }}
								/>
								<div className="remove-review-image">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"/></svg>
								</div>
							</div>
							))
						)}
						<div className="write-review-btn-sec">
							<Button
								className="wishlist-btn"
								type="submit"
								disabled={props.reviewsSave.buttonDisable}
							>
								{props.reviewsSave.loadingButtonContent ? props.reviewsSave.loadingButtonContent : t("submit") }
							</Button>
						</div>
					</Form>
				</Modal.Body>
			</Modal>
		</>
	);
};

const mapStateToPros = (state) => ({
  reviewsSaveFiles: state.reviews.reviewsSaveFiles,
  reviewsDeleteFiles: state.reviews.reviewsDeleteFiles,
  reviewsSave: state.reviews.reviewsSave,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(WriteReviewModal));