import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Accordion,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Landing.css";
import { withTranslation, useTranslation } from "react-multi-lang";
import { fetchFaqsListStart } from "../store/actions/HomePageAction";
import SomethingWrong from "../Helper/SomethingWrong";
import Skeleton from "react-loading-skeleton";

const FAQIndex = (props) => {
  const t = useTranslation();

  const [isVisible, setIsVisible] = useState(0);

  const { faqsList } = props;

  useEffect(() => {
    props.dispatch(fetchFaqsListStart());
  }, []);

  return (
    <>
      <div className="faq-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="section-title-sec">
                <h3>{t("faq")}</h3>
                {/* <h2>
                  <span>{t("what_do_you_need_to_know")}</span>
                </h2> */}
              </div>
            </Col>
          </Row>
          <Row className="margin-top-lg mt-5">
            <>
              {/* <Col md={12} xl={6} lg={6} className="resp-mrg-btm-md">
                <div className="faq-img-sec">
                  <Image
                    className="faq-img"
                    src={window.location.origin + "/images/faq/faq-img.png"}
                    type="image/svg"
                  />
                </div>
              </Col> */}

              {/* <Col md={12} xl={6} lg={6}> */}
                {faqsList.loading ? (
                  <Skeleton height={400} width={700} />
                ) : (
                  faqsList.data.faqs.map((list, index) => (
                    <Col md={12} xl={6} lg={6}>
                    <div className="faq-accordion-sec accordion accordion-flush">
                      <Accordion
                        defaultActiveKey="0"
                        activeKey={isVisible}
                        class=""
                      >
                        <Accordion.Item
                          eventKey={index}
                          onClick={() =>
                            setIsVisible(isVisible === index ? null : index)
                          }
                        >
                          <Accordion.Header>{list.title}</Accordion.Header>
                          <Accordion.Body><p dangerouslySetInnerHTML={{__html: list.description}} /></Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                    </Col>
                  ))
                )}
              {/* </Col> */}
            </>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  faqsList: state.homepage.faqsList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(FAQIndex));
