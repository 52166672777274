import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Image,
  Button,
  Tab,
  Nav,
} from "react-bootstrap";
import { withTranslation, useTranslation } from "react-multi-lang";
import {
  saveDeliveryAddressesStart,
  fetchDeliveryAddressesListStart,
} from "../store/actions/DeliveryAction";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import ProfileSideBar from "./ProfileSideBar";
import * as Yup from "yup";
import { connect } from "react-redux";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import SomethingWrong from "../Helper/SomethingWrong";
import SingleAddressCard from "./SingleAddressCard";
import InfiniteScroll from "react-infinite-scroll-component";
import CommonCenterLoader from "../Helper/CommonCenterLoader";
import AddressSubmitForm from "./AddressSubmitForm";
import NoShippingAddressFound from "../Helper/NoShippingAddressFound";

const ManageAddress = (props) => {
  const [editForm, setEditForm] = useState({});
  const [activeTab, setActiveTab] = useState("saved_billing");
  const [selectedAddress, setSelectedAddress] = useState(null);
  const t = useTranslation();
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("required")),
    contact_number: Yup.string().required(t("required")).min(5, t("min_10")),
    state: Yup.string().required(t("required")),
    pincode: Yup.string().required(t("required")),
    landmark: Yup.string().required(t("required")),
    address: Yup.string().required(t("required")),
  });

  useEffect(() => {
    props.dispatch(fetchDeliveryAddressesListStart());
  }, []);

  // useEffect(() => {
  //   console.log(props.deliveryAddressesList.data.delivery_addresses);
  // }, []);

  const handleSubmit = (values) => {
    props.dispatch(saveDeliveryAddressesStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.deliveryAddressesList.loading &&
      Object.keys(props.deliveryAddressesList.data).length > 0
    ) {
      if (
        props.deliveryAddressesList.data.delivery_addresses &&
        props.deliveryAddressesList.data.delivery_addresses.length > 0
      ) {
        props.deliveryAddressesList.data.delivery_addresses.map((add, i) => {
          if (add.is_default === 1) setSelectedAddress(add.delivery_address_id);
        });
      } else {
        setSelectedAddress(null);
      }
      setSkipRender(false);
    }
  }, [props.deliveryAddressesList]);

  useEffect(() => {
    if (
      !skipRender &&
      !props.deliveryAddressesSave.loading &&
      Object.keys(props.deliveryAddressesSave.data).length > 0
    ) {
      setActiveTab("saved_billing");
      props.dispatch(fetchDeliveryAddressesListStart());
    }
    setSkipRender(false);
  }, [props.deliveryAddressesSave]);

  useEffect(() => {
    props.dispatch(
      fetchDeliveryAddressesListStart({
        skip: 0,
        take: 4,
      })
    );
  }, []);

  const fetchMoreAddressList = () => {
    props.dispatch(
      fetchDeliveryAddressesListStart({
        skip: props.deliveryAddressesList.data.delivery_addresses.length,
        take: 4,
      })
    );
  };

  const editAddress = (address) => {
    setEditForm(address);
    setActiveTab("new_billing");
  };

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col xl={3} md={12} lg={4}>
              <div className="new-mobile-section">
                <ProfileSideBar />
              </div>
            </Col>
            <Col xl={9} md={12} lg={8}>
              <div className="profile-details-sec">
                <div className="profile-details-title">
                  <h4>{t("manage_address")}</h4>
                </div>
                <div className="address-tab mt-4">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Row>
                      <Col md={12}>
                        <Nav variant="pills">
                          <Nav.Item>
                            <Nav.Link
                              eventKey="first"
                              onClick={() => setActiveTab("saved_billing")}
                            >
                              {t("saved_address")}
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="second"
                              onClick={() => setActiveTab("new_billing")}
                            >
                              {t("new_address")}
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col md={12}>
                          {activeTab === "saved_billing" && (
                            <div className="address-label-box">
                              {props.deliveryAddressesList.loading ? (
                                <Skeleton count={2} height={100} />
                              ) : props.deliveryAddressesList.data
                                  .delivery_addresses ? (
                                props.deliveryAddressesList.data
                                  .delivery_addresses.length > 0 ? (
                                  <InfiniteScroll
                                    dataLength={
                                      props.deliveryAddressesList.data
                                        .delivery_addresses.length
                                    }
                                    next={fetchMoreAddressList}
                                    hasMore={
                                      props.deliveryAddressesList.data
                                        .delivery_addresses.length <
                                      props.deliveryAddressesList.data.total
                                    }
                                    loader={<CommonCenterLoader />}
                                  >
                                    {props.deliveryAddressesList.data.delivery_addresses.map(
                                      (address, i) => (
                                        <SingleAddressCard
                                          key={i}
                                          address={address}
                                          setSelectedAddress={
                                            setSelectedAddress
                                          }
                                          editAddress={editAddress}
                                        />
                                      )
                                    )}
                                  </InfiniteScroll>
                                ) : (
                                  <NoShippingAddressFound />
                                )
                              ) : (
                                <SomethingWrong />
                              )}
                            </div>
                          )}
                          {activeTab === "new_billing" && (
                            <AddressSubmitForm
                              editAddress={editForm}
                              setActiveTab={setActiveTab}
                            />
                          )}
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  deliveryAddressesSave: state.delivery.deliveryAddressesSave,
  deliveryAddressesList: state.delivery.deliveryAddressesList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ManageAddress));
