import React, { useState, useEffect } from 'react';
import Countdown from 'react-countdown';
import { useNavigate } from 'react-router-dom';
import { getErrorNotificationMessage } from '../Helper/ToastNotification';
import { connect } from 'react-redux';
import { withTranslation } from 'react-multi-lang';
import { checkPaymentStatusStart, initiatePaymentStart } from '../store/actions/XpayAction';
import { ordersCheckoutStart, ordersCheckoutStatusUpdateStart } from '../store/actions/OrdersAction';
import CounterTimer from './CounterTimer';
import CommonCenterLoader from '../Helper/CommonCenterLoader';


const PaymentWaitingSec = (props) => {
  let monitorTimer = null;
  const navigate = useNavigate();

  const [showTimer, setShowTimer] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [playmentCompleted, setPaymentCompleted] = useState(false);

  useEffect(() => {
    props.dispatch(ordersCheckoutStart({
      payment_mode: "CRYPTO",
      delivery_address_id: props.deliveryAddressId,
      wallet_address: props.walletAddress,
    }));
    props.dispatch(initiatePaymentStart({
      card_token: props.cardToken,
      amount: props.cartAmount,
      title: "",
      description: "",
      additional_params: "",
      xpay_merchant_key: "f473ad6ee40afd16463b9294d9abcf61",
    }))
  }, []);

  useEffect(() => {
    if (!skipRender && !props.initatePayment.loading) {
      if (Object.keys(props.initatePayment.data).length > 0) {
        setShowTimer(true);
        monitorPaymentStatus(props.initatePayment.data.data.payment_id);
      } else {
        props.setPaymentStarted(false);
      }
    }

    return () => {
      clearInterval(monitorTimer);
    };
  }, [props.initatePayment]);

  const monitorPaymentStatus = (paymentId) => {
    monitorTimer = setInterval(() => {
      props.dispatch(checkPaymentStatusStart({
        xpay_merchant_key: "f473ad6ee40afd16463b9294d9abcf61",
        payment_id: paymentId,
      }));
    }, 20000);
  }

  useEffect(() => {
    console.log("Outside", props.paymentStatus.data);
    if (!skipRender && !props.paymentStatus.loading && Object.keys(props.paymentStatus.data).length > 0) {
      console.log("Inside", props.paymentStatus.data);
      let status = 1;
      if (props.paymentStatus.data.status == 1) {
        setPaymentCompleted(true);
        // props.dispatch(ordersCheckoutStart({
        //   payment_mode: "CRYPTO",
        //   delivery_address_id: props.deliveryAddressId,
        //   transaction_hash: props.paymentStatus.data.xwallet_transaction_id,
        //   currency: props.initatePayment.data.data.currency,
        //   wallet_address: props.walletAddress,
        // }));
        props.dispatch(ordersCheckoutStatusUpdateStart({
          status: status,
          order_unique_id: props.ordersCheckout.data.order_unique_id ?? "",
          transaction_hash: props.paymentStatus.data.xwallet_transaction_id,
          currency: props.initatePayment.data.data.currency,
          payment_id: props.initatePayment.data.data.payment_id,
        }));
      } else if (props.paymentStatus.data.status == 2
        || props.paymentStatus.data.status == 3
        || props.paymentStatus.data.status == 4) {

        status = props.paymentStatus.data.status == 2 ? 4
            : props.paymentStatus.data.status == 3 ? 3 : 2;
        getErrorNotificationMessage(
          props.paymentStatus.data.status == 2
            ? "Payment has been failed. Please try again later."
            : props.paymentStatus.data.status == 3 ?
              "Payment request has been canceled."
              : "Payment request has timed out. Please try again later."
        );
        props.setPaymentStarted(false);
        props.dispatch(ordersCheckoutStatusUpdateStart({
          status: status,
          order_unique_id: props.ordersCheckout.data.order_unique_id ?? "",
          transaction_hash: props.paymentStatus.data.xwallet_transaction_id,
          currency: props.initatePayment.data.data.currency,
          payment_id: props.initatePayment.data.data.payment_id,
        }));
      }
    }
  }, [props.paymentStatus]);

  // useEffect(() => {
  //   if (!skipRender && !props.ordersCheckout.loading && Object.keys(props.ordersCheckout.data).length > 0) {
  //     localStorage.removeItem("addessUniqueId");
  //     navigate("/order-placed");
  //   }
  //   setSkipRender(false);
  // }, [props.ordersCheckout]);

  useEffect(() => {
    if (!skipRender && !props.ordersCheckoutStatusUpdate.loading && Object.keys(props.ordersCheckoutStatusUpdate.data).length > 0 && props.paymentStatus.data.status == 1) {
      localStorage.removeItem("addessUniqueId");
      navigate("/order-placed");
    }
    setSkipRender(false);
  }, [props.ordersCheckoutStatusUpdate]);

  useEffect(() => {
    console.log(showTimer);
  }, [showTimer]);



  return (
    <>
      {!playmentCompleted ?
        <div className="payment-waiting-sec">
          <h3 className='payment-notifi-sec'>Pay {props.cartAmountFormatted}</h3>
          {showTimer ?
            <>
              <p>This page will automatically expire in</p>
              <div className='new-time-count'>
                <CounterTimer setPaymentStarted={props.setPaymentStarted} />
              </div>
              <ul>
                <li><span>1</span>Visit your XPay app.</li>
                <li><span>2</span>Confirm the payment request from the app.</li>
                <li><span>3</span>Once the payment has been confirmed your order will be processed.</li>
              </ul>
            </>
            : <span className='payment-notification-status'>
              Initilizing payment...
            </span>
          }
        </div>
        : <div className="payment-waiting-sec payment-wait-title">
          Payment has been completed. Your order is being processed.
          <CommonCenterLoader />
        </div>
      }
    </>
  )
}

const mapStateToPros = (state) => ({
  initatePayment: state.xpay.initatePayment,
  paymentStatus: state.xpay.paymentStatus,
  ordersCheckout: state.orders.ordersCheckout,
  ordersCheckoutStatusUpdate: state.orders.ordersCheckoutStatusUpdate,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(PaymentWaitingSec));