import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api, { paymetUrl } from "../../Environment";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../Helper/ToastNotification";
import {
  INITIATE_PAYMENT_START,
  GET_CARD_TOKEN_START,
  CHECK_PAYMENT_STATUS_START
} from "../actions/ActionConstant";

import {
  getCardTokenSuccess,
  getCardTokenFailure,
  initiatePaymentFailure,
  initiatePaymentSuccess,
  checkPaymentStatusSuccess,
  checkPaymentStatusFailure
} from "../actions/XpayAction";

function* getCardTokenAPI(action) {
  try {
    const response = yield api.postMethod("get_ct", action.data, paymetUrl);

    if (response.data.success) {
      yield put(getCardTokenSuccess(response.data.data));
    } else {
      yield put(getCardTokenFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(getCardTokenFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* initiatePaymentAPI(action) {
  try {
    const response = yield api.postMethod("payment-initiate", action.data, paymetUrl);

    if (response.data.success) {
      yield put(initiatePaymentSuccess(response.data.data));
    } else {
      yield put(initiatePaymentFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(initiatePaymentFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* checkPaymentStatusAPI(action) {
  try {
    const response = yield api.postMethod("payment-status", action.data, paymetUrl);

    if (response.data.success) {
      yield put(checkPaymentStatusSuccess(response.data.data));
    } else {
      yield put(checkPaymentStatusFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(checkPaymentStatusFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}



export default function* XpaySaga() {
  yield all([
    yield takeLatest(GET_CARD_TOKEN_START, getCardTokenAPI),
    yield takeLatest(INITIATE_PAYMENT_START, initiatePaymentAPI),
    yield takeLatest(CHECK_PAYMENT_STATUS_START, checkPaymentStatusAPI),
  ]);
}