import "./App.css";
import Base from "./components/Base";
import "bootstrap/dist/css/bootstrap.min.css";
import store from "./components/store";
import { Provider } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import configuration from "react-global-configuration";
import { useEffect, useState } from "react";
import { apiConstants } from "./components/Constant/constants";
import { WagmiConfig, createClient, } from 'wagmi';
import { Buffer } from "buffer";
import { ConnectKitProvider, getDefaultClient } from "connectkit";

const alchemyId = process.env.ALCHEMY_ID;

const avalancheChain = {
  id: 97,
  name: "Binance",
  nativeCurrency: {
    decimals: 18,
    name: "Binance",
    symbol: "BNB",
  },
  rpcUrls: {
    default: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  },
  blockExplorers: {
    default: { name: "Binance Test", url: "https://testnet.bscscan.com" },
    // snowtrace: { name: "SnowTrace", url: "https://testnet.bscscan.com" },
  },
  testnet: true,
};

const chains = [avalancheChain];
if (!window.Buffer) window.Buffer = Buffer;
const client = createClient({
  ...getDefaultClient({
    appName: "Your App Name",
    alchemyId,
    chains,
  }),
});


function App() {

  const [configLoading, setConfigLoading] = useState(true);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await fetch(apiConstants.settingsUrl);
      const configValue = await response.json();
      configuration.set({ configData: configValue.data }, { freeze: false });
      setConfigLoading(false);
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      setConfigLoading(false);
    }
  };

  return (
    <Provider store={store}>
      <WagmiConfig client={client}>
        <ConnectKitProvider>
          {!configLoading && (
            <Base />
          )}
        </ConnectKitProvider>
      </WagmiConfig>
    </Provider>
  );
}

export default App;
