import React, { useState } from "react";
import { withTranslation, useTranslation } from "react-multi-lang";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Landing.css";

const LandingAboutIndex = (props) => {
  const t = useTranslation();

  return (
    <>
      <div className="landing-about-sec">
        <div className="landing-about-box">
          <div className="landing-about-card-left">
            <div className="section-title-sec">
              <h3>{t("about_us")}</h3>
              {/* <h2>
                <span>{t("about_us_text")}</span>
              </h2> */}
            </div>
            <div className="landing-about-info text-center">
              <p>{t("about_content_1")}</p>
              <p>{t("about_content_2")}</p>
              <p>{t("about_content_3")}</p>
            </div>
          </div>
          {/* <div className="landing-about-card-right">
            <div className="landing-about-card-right-img-sec">
              <Image
                className="landing-about-card-right-img"
                src={window.location.origin + "/images/about-img-1.png"}
                type="image/png"
              />
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default withTranslation(LandingAboutIndex);
