import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../Helper/ToastNotification";
import {
  SAVE_ORDERS_DIRECT_START,
  SAVE_ORDERS_CART_START,
  FETCH_ORDERS_LIST_START,
  FETCH_ORDERS_DETAILS_START,
  CANCEL_ORDERS_START,
  INVOICE_ORDERS_START,
  FETCH_MORE_ORDERS_LIST_START,
  TRANSACTION_LIST_START,
  MORE_TRANSACTION_LIST_START,
  ORDERS_SEND_CANCEL_REQUEST_START,
  ORDERS_SEND_RETURN_REQUEST_START,
  ORDERS_CHECKOUT_START,
  ORDERS_CHECKOUT_STATUS_UPDATE_START,
  ORDERS_CHECKOUT_STATUS_UPDATE_FAILURE,
  ORDERS_CHECKOUT_STATUS_UPDATE_SUCCESS
} from "../actions/ActionConstant";

import {
  saveOrdersDirectSuccess,
  saveOrdersDirectFailure,
  saveOrdersCartSuccess,
  saveOrdersCartFailure,
  fetchOrdersListStart,
  fetchOrdersListSuccess,
  fetchOrdersListFailure,
  fetchOrdersDetailsSuccess,
  fetchOrdersDetailsFailure,
  cancelOrdersSuccess,
  cancelOrdersFailure,
  invoiceOrdersSuccess,
  invoiceOrdersFailure,
  transactionListSuccess,
  transactionListFailure,
  ordersSendCancelRequestSuccess,
  ordersSendCancelRequestFailure,
  ordersSendReturnRequestSuccess,
  ordersSendReturnRequestFailure,
  ordersCheckoutSuccess,
  ordersCheckoutFailure,
  ordersCheckoutStatusUpdateSuccess,
  ordersCheckoutStatusUpdateFailure
} from "../actions/OrdersAction";

function* saveOrdersDirectAPI(action) {
  try {
    const response = yield api.postMethod("orders_direct_checkout", action.data);

    if (response.data.success) {
      yield put(saveOrdersDirectSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.message);
    } else {
      yield put(saveOrdersDirectFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(saveOrdersDirectFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }

}

function* saveOrdersCartAPI(action) {
  try {
    const response = yield api.postMethod("orders_cart_checkout", action.data);

    if (response.data.success) {
      yield put(saveOrdersCartSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.message);
    } else {
      yield put(saveOrdersCartFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(saveOrdersCartFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }

}

function* fetchOrdersListAPI(action) {
  try {
    const response = yield api.postMethod("orders_list", action.data);

    if (response.data.success) {
      yield put(fetchOrdersListSuccess(response.data.data));
    } else {
      yield put(fetchOrdersListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchOrdersListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchOrdersDetailsAPI(action) {
  try {
    const response = yield api.postMethod("orders_view", action.data);

    if (response.data.success) {
      yield put(fetchOrdersDetailsSuccess(response.data.data));
    } else {
      yield put(fetchOrdersDetailsFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(fetchOrdersDetailsFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* cancelOrdersAPI(action) {
  try {
    const response = yield api.postMethod("orders_cancel", action.data);

    if (response.data.success) {
      yield put(cancelOrdersSuccess(response.data.data));
    } else {
      yield put(cancelOrdersFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(cancelOrdersFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* invoiceOrdersAPI(action) {
  try {
    const response = yield api.postMethod("orders_invoice", action.data);

    if (response.data.success) {
      yield put(invoiceOrdersSuccess(response.data.data));
    } else {
      yield put(invoiceOrdersFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(invoiceOrdersFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchtransactionListAPI(action) {
  try {
    const response = yield api.postMethod("order_payments_list", action.data);

    if (response.data.success) {
      yield put(transactionListSuccess(response.data.data));
    } else {
      yield put(transactionListFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(transactionListFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}


function* ordersSendCancelRequestAPI(action) {
  try {
    const response = yield api.postMethod("orders_send_cancel_request", action.data);

    if (response.data.success) {
      yield put(ordersSendCancelRequestSuccess(response.data.data));
      yield put(fetchOrdersDetailsSuccess(response.data.data));
      yield call(getSuccessNotificationMessage, response.data.message);
    } else {
      yield put(ordersSendCancelRequestFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(ordersSendCancelRequestFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* ordersSendReturnRequestAPI(action) {
  try {
    const response = yield api.postMethod("orders_send_return_request", action.data);

    if (response.data.success) {
      yield put(ordersSendReturnRequestSuccess(response.data.data));
      yield put(fetchOrdersDetailsSuccess(response.data.data));
    } else {
      yield put(ordersSendReturnRequestFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(ordersSendReturnRequestFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* ordersCheckoutAPI(action) {
  try {
    const response = yield api.postMethod("orders_checkout", action.data);

    if (response.data.success) {
      yield put(ordersCheckoutSuccess(response.data.data));
    } else {
      yield put(ordersCheckoutFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(ordersCheckoutFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* ordersCheckoutStatusUpdateAPI(action) {
  try {
    const response = yield api.postMethod("orders_checkout_status_update", action.data);

    if (response.data.success) {
      yield put(ordersCheckoutStatusUpdateSuccess(response.data.data));
    } else {
      yield put(ordersCheckoutStatusUpdateFailure(response.data.error));
      yield call(getErrorNotificationMessage, response.data.error);
    }
  } catch (error) {
    yield put(ordersCheckoutStatusUpdateFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}



export default function* pageSaga() {
  yield all([
    yield takeLatest(SAVE_ORDERS_DIRECT_START, saveOrdersDirectAPI),
    yield takeLatest(SAVE_ORDERS_CART_START, saveOrdersCartAPI),
    yield takeLatest(FETCH_ORDERS_LIST_START, fetchOrdersListAPI),
    yield takeLatest(FETCH_MORE_ORDERS_LIST_START, fetchOrdersListAPI),
    yield takeLatest(FETCH_ORDERS_DETAILS_START, fetchOrdersDetailsAPI),
    yield takeLatest(CANCEL_ORDERS_START, cancelOrdersAPI),
    yield takeLatest(INVOICE_ORDERS_START, invoiceOrdersAPI),
    yield takeLatest(TRANSACTION_LIST_START, fetchtransactionListAPI),
    yield takeLatest(MORE_TRANSACTION_LIST_START, fetchtransactionListAPI),
    yield takeLatest(ORDERS_SEND_CANCEL_REQUEST_START, ordersSendCancelRequestAPI),
    yield takeLatest(ORDERS_SEND_RETURN_REQUEST_START, ordersSendReturnRequestAPI),
    yield takeLatest(ORDERS_CHECKOUT_START, ordersCheckoutAPI),
    yield takeLatest(ORDERS_CHECKOUT_STATUS_UPDATE_START, ordersCheckoutStatusUpdateAPI),
  ]);
}