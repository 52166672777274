import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import walletReducer from "./WalletReducer";
import cartsReducer from "./CartsReducer";
import ordersReducer from "./OrdersReducer";
import deliveryReducer from "./DeliveryReducer";
import homePageReducer from "./HomePageReducer";
import reviewsReducer from "./ReviewsReducer";
import PageReducer from "./PageReducer";
import ProductReducer from "./ProductReducer";
import XpayReducer from "./XpayReducer";

export default combineReducers({
  users: UserReducer,
  wallet: walletReducer,
  carts: cartsReducer,
  orders: ordersReducer,
  page: PageReducer,
  delivery: deliveryReducer,
  homepage: homePageReducer,
  reviews: reviewsReducer,
  product: ProductReducer,
  xpay: XpayReducer,
});
