import React, { useEffect, useState } from "react";
import {
  fetchDeliveryAddressesListStart,
  makeDefaultDeliveryAddressesStart,
  deleteDeliveryAddressesStart,
} from "../store/actions/DeliveryAction";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";

const SingleAddressCard = (props) => {

  const { selectedAddress, setSelectedAddress, address, handleEdit } = props;
  const [skipRender, setSkipRender] = useState(true);
  const t = useTranslation();


  const removeAddress = (e) => {
    e.preventDefault();
    props.dispatch(
      deleteDeliveryAddressesStart({
        delivery_address_id: address.delivery_address_id,
      })
    );
  };

  const setDefaultAddress = (e) => {
    props.dispatch(
      makeDefaultDeliveryAddressesStart({
        delivery_address_id: address.delivery_address_id,
      })
    );
    e.stopPropagation();
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.makeDefaultDeliveryAddresses.loading &&
      Object.keys(props.makeDefaultDeliveryAddresses.data).length > 0
    ) {
      props.dispatch(fetchDeliveryAddressesListStart());
    }
    setSkipRender(false);
  }, [props.makeDefaultDeliveryAddresses]);

  // useEffect(() => {
  //   console.log(address)
  // }, [])


  return (
    <div className="address-radio-btn">
      <input name="plan" className="radio" type="radio" id="card" checked={selectedAddress === address.delivery_address_id} />
      <label name="plan" className="radio" type="radio" for="card" checked={selectedAddress === address.delivery_address_id} onClick={() => setSelectedAddress(address.delivery_address_id)} >
        <div className="saved-address-box">
          <div className="address-details">
            <h4>{address.name}</h4>
            <p>{address.address}</p>
            <p>{address.city}, {address.state}, {address.pincode}</p>
            <p>{address.contact_number}</p>
          </div>
          <div className="address-btn">
            <Link to="" className="address-action-btn">
              <span onClick={() => props.editAddress(address)}>
                <Image
                  src={window.location.origin + "/images/edit-black.svg"}
                  type="image/png"
                /></span>
              {props.deliveryAddressesDelete.loadingButtonContent &&
                props.deliveryAddressesDelete.data.delivery_address_id ===
                address.delivery_address_id ? (
                props.deliveryAddressesDelete.loadingButtonContent
              ) : (
                <button onClick={(event) => removeAddress(event)} className="delete-btn size">
                  <Image src={window.location.origin + "/images/delete-solid.svg"}
                    type='image/svg'
                  />
                </button>
              )}

            </Link>

            {address.is_default == 1 ?
              (<span className="address-button text-white">{t("default")}</span>)
              : props.makeDefaultDeliveryAddresses.loadingButtonContent &&
                props.makeDefaultDeliveryAddresses.data.delivery_address_id === address.delivery_address_id ?
                (props.loadingButtonContent) :
                (<button to="" className="address-button" onClick={setDefaultAddress}>
                  {t("use_address")}
                </button>)
            }
          </div>
        </div>
      </label>
    </div>

  );
};

const mapStateToPros = (state) => ({
  makeDefaultDeliveryAddresses: state.delivery.makeDefaultDeliveryAddresses,
  deliveryAddressesDelete: state.delivery.deliveryAddressesDelete,
  deliveryAddressesList: state.delivery.deliveryAddressesList
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(SingleAddressCard));
