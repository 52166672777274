import {
  GET_CARD_TOKEN_START,
  GET_CARD_TOKEN_SUCCESS,
  GET_CARD_TOKEN_FAILURE,
  INITIATE_PAYMENT_START,
  INITIATE_PAYMENT_SUCCESS,
  INITIATE_PAYMENT_FAILURE,
  CHECK_PAYMENT_STATUS_START,
  CHECK_PAYMENT_STATUS_SUCCESS,
  CHECK_PAYMENT_STATUS_FAILURE
} from "../actions/ActionConstant";

const initialState = {
  cardToken: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  initatePayment: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
  },
  paymentStatus: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  }
};

const XpayReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CARD_TOKEN_START:
      return {
        ...state,
        cardToken: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading"
        }
      };
    case GET_CARD_TOKEN_SUCCESS:
      return {
        ...state,
        cardToken: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case GET_CARD_TOKEN_FAILURE:
      return {
        ...state,
        cardToken: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case INITIATE_PAYMENT_START:
      return {
        ...state,
        initatePayment: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case INITIATE_PAYMENT_SUCCESS:
      return {
        ...state,
        initatePayment: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case INITIATE_PAYMENT_FAILURE:
      return {
        ...state,
        initatePayment: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case CHECK_PAYMENT_STATUS_START:
      return {
        ...state,
        paymentStatus: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading"
        }
      };
    case CHECK_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        paymentStatus: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case CHECK_PAYMENT_STATUS_FAILURE:
      return {
        ...state,
        paymentStatus: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    default:
      return state;
  }
}


export default XpayReducer;

