import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Dropdown,
  InputGroup,
  Form,
  Button,
} from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import { withTranslation, useTranslation } from "react-multi-lang";
import configuration from "react-global-configuration";
import { guestCartList } from "../../store/actions/CartsAction";
import SearchSuggetion from "../../Helper/SearchSuggetion";
import { fetchProductSuggetionStart } from "../../store/actions/ProductAction";

const HeaderIndex = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const t = useTranslation();

  const [search, setSearch] = useState("");
  const [showSuggetions, setShowSuggetions] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken")) {
      props.dispatch(fetchUserDetailsStart());
    } else {
      props.dispatch(
        guestCartList(
          localStorage.getItem("carts")
            ? JSON.parse(localStorage.getItem("carts"))
            : []
        )
      );
    }
  }, []);

  const getSuggetions = (e) => {
    e.preventDefault();
    const searchValue = e.target.value;
    setSearch(searchValue);
    if (searchValue) {
      props.dispatch(
        fetchProductSuggetionStart({
          search_key: searchValue,
        })
      );
      setShowSuggetions(true);
    } else {
      setShowSuggetions(false);
    }
  };

  const searchProduct = (e) => {
    e.preventDefault();
    setShowSuggetions(false);
    navigate(`/shop/${search}`);
  };

  return (
    <>
      <header className="header-nav-center">
        <Navbar expand="lg">
          <Container>
            <Link to="/" className="navbar-brand">
              <Image
                className="lazyload logo"
                src={configuration.get("configData.site_logo")}
                type="image/png"
              />
            </Link>
            <div className="user-dropdown-sec">
              {!localStorage.getItem("userId") ||
              !localStorage.getItem("accessToken") ? (
                <div className="mobile-nav-profile ">
                  <Link to="/login">
                    {/* <span>
                           <Image
                             className="header-dropdown-item-icon"
                             src={
                               window.location.origin +
                               "/images/icons/sign-in-icon.svg"
                             }
                           />
                         </span> */}
                    <Button className="subscribe-btn" type="submit">
                      {t("sign_in")}
                    </Button>
                  </Link>
                </div>
              ) : (
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <div className="mobile-nav-profile contact-address-img ">
                      <Image
                        className="header-user-icon"
                        src={window.location.origin + "/images/user.svg"}
                      />
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Link to="/cart">
                      <span>
                        <Image
                          className="header-dropdown-item-icon"
                          src={
                            window.location.origin +
                            "/images/icons/shopping-cart.svg"
                          }
                        />
                      </span>
                      {t("view_cart")}
                    </Link>
                    <Link to="/wishlist">
                      <span>
                        <Image
                          className="header-dropdown-item-icon"
                          src={
                            window.location.origin +
                            "/images/icons/wishlist.svg"
                          }
                        />
                      </span>
                      {t("wishlist")}
                    </Link>
                    <Link to="/profile">
                      <span>
                        <Image
                          className="header-user-icon"
                          src={window.location.origin + "/images/user.svg"}
                        />
                      </span>
                      {t("profile")}
                    </Link>
                    <Link to="/logout">
                      <span>
                        <Image
                          className="header-user-icon"
                          src={
                            window.location.origin + "/images/logout-img.svg"
                          }
                        />
                      </span>
                      {t("logout")}
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav mobile-toggle-sec">
              <Image
                className="lazyload hamburger-menu"
                src={window.location.origin + "/images/hamburger-menu.png"}
                type="image/png"
              />
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav" className="new-header-sec">
              <Nav className="new-navbar-sec m-auto">
                <Link
                  to="/"
                  className={`nav-link ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                >
                  {t("home")}
                </Link>
                <Link
                  to="/shop"
                  className={`nav-link ${
                    location.pathname === "/shop" ? "active" : ""
                  }`}
                >
                  {t("shop")}
                </Link>
              </Nav>
              <Nav className="header-search-sec ms-auto">
                <div className="new-header-search">
                  <Form onSubmit={searchProduct}>
                    <InputGroup className="mb-0">
                      <InputGroup.Text id="basic-addon2">
                        <Image
                          className="header-search-icon"
                          src={
                            window.location.origin + "/images/icons/search.svg"
                          }
                        />
                      </InputGroup.Text>
                      <Form.Control
                        value={search}
                        onChange={getSuggetions}
                        placeholder={t("search_placeholder")}
                      />
                    </InputGroup>
                  </Form>
                  {showSuggetions ? (
                    <SearchSuggetion setShowSuggetions={setShowSuggetions} />
                  ) : null}
                </div>
                <div className="header-profile-sec">
                  <div className="user-dropdown-sec">
                    {!localStorage.getItem("userId") ||
                    !localStorage.getItem("accessToken") ? (
                      <div className="">
                        <Link to="/login">
                          {/* <span>
                           <Image
                             className="header-dropdown-item-icon"
                             src={
                               window.location.origin +
                               "/images/icons/sign-in-icon.svg"
                             }
                           />
                         </span> */}
                          <Button className="subscribe-btn" type="submit">
                            {t("sign_in")}
                          </Button>
                        </Link>
                      </div>
                    ) : (
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <div className="desktop-user-img">
                            <Image
                              className="header-user-icon "
                              src={window.location.origin + "/images/user.svg"}
                            />
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Link to="/cart">
                            <span>
                              <Image
                                className="header-dropdown-item-icon"
                                src={
                                  window.location.origin +
                                  "/images/icons/shopping-cart.svg"
                                }
                              />
                            </span>
                            {t("view_cart")}
                          </Link>
                          <Link to="/wishlist">
                            <span>
                              <Image
                                className="header-dropdown-item-icon"
                                src={
                                  window.location.origin +
                                  "/images/icons/wishlist.svg"
                                }
                              />
                            </span>
                            {t("wishlist")}
                          </Link>
                          <Link to="/profile">
                            <span>
                              <Image
                                className="header-user-icon"
                                src={
                                  window.location.origin + "/images/user.svg"
                                }
                              />
                            </span>
                            {t("profile")}
                          </Link>
                          <Link to="/logout">
                            <span>
                              <Image
                                className="header-user-icon"
                                src={
                                  window.location.origin +
                                  "/images/logout-img.svg"
                                }
                              />
                            </span>
                            {t("logout")}
                          </Link>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                  </div>
                </div>
                <Link to="/cart" className="header-cart-item">
                  <Image
                    className="header-cart-icon"
                    src={
                      window.location.origin + "/images/icons/shopping-cart.svg"
                    }
                  />
                  <span className="add-cart-count">
                    {localStorage.getItem("userId")
                      ? Object.keys(props.profile.data).length > 0
                        ? props.profile.data.cart_count
                        : 0
                      : props.guestCartList.length}
                  </span>
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="new-header-search-mobile">
          <Form onSubmit={searchProduct}>
            <InputGroup className="mb-0">
              <InputGroup.Text id="basic-addon2">
                <Image
                  className="header-search-icon"
                  src={window.location.origin + "/images/icons/search.svg"}
                />
              </InputGroup.Text>
              <Form.Control
                value={search}
                onChange={getSuggetions}
                placeholder={t("search_placeholder")}
              />
            </InputGroup>
          </Form>
          {showSuggetions ? (
            <SearchSuggetion setShowSuggetions={setShowSuggetions} />
          ) : null}
        </div>
      </header>
      <div className="mobile-header-sec">
        <Nav>
          <Link
            to="/"
            className={`nav-link ${location.pathname === "/" ? "active" : ""}`}
          >
            <div class="contact-address-img">
              <svg
                width="683"
                height="683"
                viewBox="0 0 683 683"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1_28)">
                  <path d="M674.854 322.479C656.958 304.583 373.307 20.9325 360.187 7.81276C349.776 -2.6039 332.89 -2.6039 322.479 7.81276C308.13 22.1617 25.4114 304.875 7.81249 322.479C-2.60416 332.891 -2.60416 349.776 7.81249 360.193C18.2239 370.604 35.1093 370.604 45.5208 360.193L52.4427 353.271V656C52.4427 670.729 64.3854 682.666 79.1093 682.666H603.557C618.281 682.666 630.223 670.729 630.223 656V353.271L637.14 360.193C647.557 370.604 664.442 370.604 674.854 360.193C685.27 349.776 685.27 332.891 674.854 322.479V322.479ZM419.557 629.333H263.114V472.891H419.557V629.333ZM576.89 629.333H472.89V446.224C472.89 431.495 460.953 419.557 446.224 419.557H236.448C221.719 419.557 209.781 431.495 209.781 446.224V629.333H105.781V299.938L341.333 64.3804L576.89 299.938V629.333Z" />
                </g>
              </svg>
            </div>
          </Link>
          <Link
            to="/shop"
            className={`nav-link ${
              location.pathname === "/shop" ? "active" : ""
            }`}
          >
            <div class="contact-address-img">
              <svg
                width="512"
                height="512"
                viewBox="0 0 512 512"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1_4)">
                  <path d="M98.6656 271.176C131.547 271.176 160.507 254.958 178.387 230.122C195.617 254.906 224.203 271.176 256.626 271.176C288.965 271.176 317.529 254.99 334.774 230.315C352.549 255.032 381.518 271.176 414.229 271.176C476.974 271.176 524.43 210.372 509.1 149.51L479.186 30.748C474.642 12.7041 458.456 0.102783 439.834 0.102783H71.9397C53.3172 0.102783 37.1367 12.7041 32.5918 30.7491L2.8706 148.804C-5.60898 182.471 5.53674 211.809 25.2211 238.52V471.357C25.2211 493.71 43.2684 511.895 65.6418 511.895H446.282C468.654 511.895 486.71 493.709 486.71 471.357V320.355C486.71 310.56 478.47 302.62 468.666 302.62C458.862 302.62 450.622 310.559 450.622 320.355V471.358C450.622 474.154 449.078 476.902 446.282 476.902H334.703V338.386C334.703 328.591 326.742 320.52 316.938 320.52H194.995C185.192 320.52 177.229 328.59 177.229 338.386V476.902H65.6418C62.8456 476.902 61.3091 474.152 61.3091 471.358V263.729C72.2448 268.595 85.4738 271.176 98.6656 271.176V271.176ZM352.2 35.0972H439.833C442.161 35.0972 444.183 36.9103 444.752 39.1653L474.66 158.045C484.385 196.663 454.284 235.645 414.373 235.645C379.998 235.645 352.2 207.747 352.2 173.381V35.0972ZM196.912 35.0972H317.206V173.381C317.206 206.767 291.086 235.705 256.992 235.705C222.34 235.705 196.912 206.443 196.912 172.814V35.0972ZM213.316 356.608H298.615V476.902H213.316V356.608ZM37.2701 157.46L67.011 39.1675C67.5796 36.9114 69.6126 35.0983 71.9397 35.0983H160.824V172.816C160.824 207.479 132.817 235.681 98.1495 235.705C57.8151 235.68 27.4257 196.552 37.2701 157.46Z" />
                </g>
              </svg>
            </div>
          </Link>
          <Link
            to="/cart"
            className={`nav-link ${
              location.pathname === "/cart" ? "active" : ""
            }`}
          >
            <div class="contact-address-img">
              <svg
                width="512"
                height="512"
                viewBox="0 0 512 512"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M473.668 139.109L410.282 303.914H153.733L98.973 30.118H0V71.187H65.305L120.064 344.984H438.488L512 153.853L473.668 139.109Z" />
                <path d="M191.658 358.674C157.689 358.674 130.054 386.308 130.054 420.278C130.054 454.248 157.69 481.882 191.658 481.882C225.627 481.882 253.262 454.248 253.262 420.278C253.262 386.308 225.626 358.674 191.658 358.674ZM191.658 440.813C180.335 440.813 171.123 431.602 171.123 420.278C171.123 408.954 180.335 399.743 191.658 399.743C202.981 399.743 212.193 408.954 212.193 420.278C212.193 431.602 202.981 440.813 191.658 440.813Z" />
                <path d="M369.626 358.674C335.657 358.674 308.022 386.308 308.022 420.278C308.022 454.248 335.658 481.882 369.626 481.882C403.595 481.882 431.23 454.248 431.23 420.278C431.23 386.308 403.594 358.674 369.626 358.674ZM369.626 440.813C358.303 440.813 349.091 431.602 349.091 420.278C349.091 408.954 358.303 399.743 369.626 399.743C380.949 399.743 390.161 408.954 390.161 420.278C390.161 431.602 380.949 440.813 369.626 440.813Z" />
              </svg>
            </div>
            <span className="add-cart-count">
              {localStorage.getItem("userId")
                ? Object.keys(props.profile.data).length > 0
                  ? props.profile.data.cart_count
                  : 0
                : props.guestCartList.length}
            </span>
          </Link>
        </Nav>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  guestCartList: state.carts.guestCartList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(HeaderIndex));
