import {
  SAVE_DELIVERY_ADDRESSES_START,
  SAVE_DELIVERY_ADDRESSES_SUCCESS,
  SAVE_DELIVERY_ADDRESSES_FAILURE,
  FETCH_DELIVERY_ADDRESSES_LIST_START,
  FETCH_DELIVERY_ADDRESSES_LIST_SUCCESS,
  FETCH_DELIVERY_ADDRESSES_LIST_FAILURE,
  MAKE_DEFAULT_DELIVERY_ADDRESSES_START,
  MAKE_DEFAULT_DELIVERY_ADDRESSES_SUCCESS,
  MAKE_DEFAULT_DELIVERY_ADDRESSES_FAILURE,
  DELETE_DELIVERY_ADDRESSES_START,
  DELETE_DELIVERY_ADDRESSES_SUCCESS,
  DELETE_DELIVERY_ADDRESSES_FAILURE,
  FETCH_SINGLE_DELIVERY_ADDRESS_START,
  FETCH_SINGLE_DELIVERY_ADDRESS_SUCCESS,
  FETCH_SINGLE_DELIVERY_ADDRESS_FAILURE,
} from "./ActionConstant";


export function saveDeliveryAddressesStart(data) {
  return {
    type: SAVE_DELIVERY_ADDRESSES_START,
    data,
  };
}

export function saveDeliveryAddressesSuccess(data) {
  return {
    type: SAVE_DELIVERY_ADDRESSES_SUCCESS,
    data,
  };
}

export function saveDeliveryAddressesFailure(error) {
  return {
    type: SAVE_DELIVERY_ADDRESSES_FAILURE,
    error,
  };
}

export function fetchDeliveryAddressesListStart(data) {
  return {
    type: FETCH_DELIVERY_ADDRESSES_LIST_START,
    data,
  };
}

export function fetchDeliveryAddressesListSuccess(data) {
  return {
    type: FETCH_DELIVERY_ADDRESSES_LIST_SUCCESS,
    data,
  };
}

export function fetchDeliveryAddressesListFailure(error) {
  return {
    type: FETCH_DELIVERY_ADDRESSES_LIST_FAILURE,
    error,
  };
}

export function makeDefaultDeliveryAddressesStart(data) {
  return {
    type: MAKE_DEFAULT_DELIVERY_ADDRESSES_START,
    data,
  };
}

export function makeDefaultDeliveryAddressesSuccess(data) {
  return {
    type: MAKE_DEFAULT_DELIVERY_ADDRESSES_SUCCESS,
    data,
  };
}

export function makeDefaultDeliveryAddressesFailure(error) {
  return {
    type: MAKE_DEFAULT_DELIVERY_ADDRESSES_FAILURE,
    error,
  };
}

export function deleteDeliveryAddressesStart(data) {
  return {
    type: DELETE_DELIVERY_ADDRESSES_START,
    data,
  };
}

export function deleteDeliveryAddressesSuccess(data) {
  return {
    type: DELETE_DELIVERY_ADDRESSES_SUCCESS,
    data,
  };
}

export function deleteDeliveryAddressesFailure(error) {
  return {
    type: DELETE_DELIVERY_ADDRESSES_FAILURE,
    error,
  };
}

//Single Delivery Address
export function fetchSingleDeliveryAddressStart(data) {
  return {
    type: FETCH_SINGLE_DELIVERY_ADDRESS_START,
    data,
  };
}

export function fetchSingleDeliveryAddressSuccess(data) {
  return {
    type: FETCH_SINGLE_DELIVERY_ADDRESS_SUCCESS,
    data,
  };
}

export function fetchSingleDeliveryAddressFailure(error) {
  return {
    type: FETCH_SINGLE_DELIVERY_ADDRESS_FAILURE,
    error,
  }
}