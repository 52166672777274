import React from "react";
import { withTranslation, useTranslation } from "react-multi-lang";
import { Container, Row, Col, Image, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const AboutUs = () => {
  const t = useTranslation();

  return (
    <>
      <div className="contact-sec">
        <div className="contact-sec-bg about-sec-bg">
          <div className="contact-banner-content">
            <h2>{t("about_us")}</h2>
          </div>
        </div>
        <div className="about-us-sec sm-padding">
          <Container>
            <Row className="align-items-center gy-4">
              <Col md={6}>
                <div className="contact-form-img text-center">
                  <Image
                    src={window.location.origin + "/images/about-img.jpg"}
                    type="image/png"
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="about-us-content">
                  <h3>{t("we_have_everything_you_need")}</h3>
                  <p>{t("about_us_page_content_1")}</p>
                  <p>{t("about_us_page_content_2")}</p>
                  <p>{t("about_us_page_content_3")}</p>
                  <Link to="/contact-us" className="wishlist-btn mt-3">
                    {t("contact_us")}
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="connect-with-us-sec sm-padding">
          <Container>
            <Row className="justify-content-center">
              <Col md={8}>
                <div className="section-title text-center">
                  <h5>{t("top_selling_products")}</h5>
                  <h2>{t("our_services")}</h2>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center g-4">
              <Col md={6}>
                <div className="about-us-card">
                  <Image
                    src={window.location.origin + "/images/support.png"}
                    type="image/png"
                  />
                  <h5>{t("24x7_support")}</h5>
                  <p>
                    We are aware of the uncertainties and the volatile nature of
                    the two industries we have merged in the making of
                    PoshMarket, which is why we offer round the clock support
                    for all your queries.
                  </p>
                </div>
              </Col>
              <Col md={6}>
                <div className="about-us-card">
                  <Image
                    src={window.location.origin + "/images/shield-1.png"}
                    type="image/png"
                  />
                  <h5>{t("100_percent_safe_products")}</h5>
                  <p>
                    {" "}
                    Customer satisfaction is of utmost importance to us, which
                    is why all our products are sourced from the best and
                    trusted vendors around the world making our products
                    completely safe to use.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="about-us-sec sm-padding">
          <Container>
            <Row className="align-items-center gy-4">
              <Col md={6}>
                <div className="about-us-content">
                  <h3>{t("we_have_everything_you_need")}</h3>
                  <p>
                    IGNITE disrupts marketing norms with an authentic,
                    quality-first mission, taking its expansion world-wide.
                    Following its September 2018 US launch, 2019 saw expansion
                    into the UK and Mexico, with continued expansion in 2020 to
                    the Middle East and Canada with many more to come.
                  </p>
                  <p>
                    IGNITE is unapologetic in our attitude and in our promise to
                    provide the ultimate experience to our customers.
                  </p>
                  <p>It will always be the best or nothing for IGNITE.</p>
                  <Link to="/contact-us" className="wishlist-btn mt-3">
                    Contact Us
                  </Link>
                </div>
              </Col>
              <Col md={6}>
                <div className="contact-form-img text-center">
                  <Image
                    src={window.location.origin + "/images/about-img-2.jpg"}
                    type="image/png"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default withTranslation(AboutUs);
