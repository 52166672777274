import {
  FETCH_MORE_PRODUCT_LIST_START,
  FETCH_MORE_PRODUCT_REVIEW_LIST_START,
  FETCH_PRODUCT_LIST_FAILURE,
  FETCH_PRODUCT_LIST_START,
  FETCH_PRODUCT_LIST_SUCCESS,
  FETCH_PRODUCT_REVIEW_LIST_FAILURE,
  FETCH_PRODUCT_REVIEW_LIST_START,
  FETCH_PRODUCT_REVIEW_LIST_SUCCESS,
  FETCH_SINGLE_PRODUCT_FAILURE,
  FETCH_SINGLE_PRODUCT_START,
  FETCH_SINGLE_PRODUCT_SUCCESS,
  FORCE_PRODUCT_LIST_SUCCESS,
  FETCH_PRODUCT_WISHLIST_LIST_START,
  FETCH_MORE_PRODUCT_WISHLIST_LIST_START,
  FETCH_PRODUCT_WISHLIST_LIST_FAILURE,
  FETCH_PRODUCT_WISHLIST_LIST_SUCCESS,
  SAVE_WISHLIST_START,
  SAVE_WISHLIST_SUCCESS,
  SAVE_WISHLIST_FAILURE,
  FETCH_PRODUCT_SUGGETION_SUCCESS,
  FETCH_PRODUCT_SUGGETION_FAILURE,
  FETCH_PRODUCT_SUGGETION_START,
  FORCE_PRODUCT_WISHLIST_LIST_SUCCESS,
} from "./ActionConstant";


export function fetchProductListStart(data) {
  return {
    type: FETCH_PRODUCT_LIST_START,
    data,
  };
}

export function fetchMoreProductListStart(data) {
  return {
    type: FETCH_MORE_PRODUCT_LIST_START,
    data,
  }
}

export function fetchProductListSuccess(data) {
  return {
    type: FETCH_PRODUCT_LIST_SUCCESS,
    data,
  };
}

export function forceProductListSuccess(data) {
  return {
    type: FORCE_PRODUCT_LIST_SUCCESS,
    data,
  }
}

export function fetchProductListFailure(error) {
  return {
    type: FETCH_PRODUCT_LIST_FAILURE,
    error,
  };
}

// Single Product
export function fetchSingleProductStart(data) {
  return {
    type: FETCH_SINGLE_PRODUCT_START,
    data,
  };
}

export function fetchSingleProductSuccess(data) {
  return {
    type: FETCH_SINGLE_PRODUCT_SUCCESS,
    data,
  };
}

export function fetchSingleProductFailure(error) {
  return {
    type: FETCH_SINGLE_PRODUCT_FAILURE,
    error,
  };
}

// Product Reviews
export function fetchProductReviewListStart(data) {
  return {
    type: FETCH_PRODUCT_REVIEW_LIST_START,
    data,
  };
}

export function fetchMoreProductReviewListStart(data) {
  return {
    type: FETCH_MORE_PRODUCT_REVIEW_LIST_START,
    data,
  }
}

export function fetchProductReviewListSuccess(data) {
  return {
    type: FETCH_PRODUCT_REVIEW_LIST_SUCCESS,
    data,
  };
}

export function fetchProductreviewListFailure(error) {
  return {
    type: FETCH_PRODUCT_REVIEW_LIST_FAILURE,
    error,
  };
}

//Product Wishlist

export function fetchProductWishlistListStart(data) {
  return {
    type: FETCH_PRODUCT_WISHLIST_LIST_START,
    data,
  };
}

export function fetchMoreProductWishlistListStart(data) {
  return {
    type: FETCH_MORE_PRODUCT_WISHLIST_LIST_START,
    data,
  }
}

export function fetchProductWishlistListSuccess(data) {
  return {
    type: FETCH_PRODUCT_WISHLIST_LIST_SUCCESS,
    data,
  };
}

export function forceProductWishlistListSuccess(data) {
  return {
    type: FORCE_PRODUCT_WISHLIST_LIST_SUCCESS,
    data,
  }
}

export function fetchProductWishlistListFailure(error) {
  return {
    type: FETCH_PRODUCT_WISHLIST_LIST_FAILURE,
    error,
  };
}

export function saveWishlistsStart(data) {
  return {
    type: SAVE_WISHLIST_START,
    data,
  };
}

export function saveWishlistsSuccess(data) {
  return {
    type: SAVE_WISHLIST_SUCCESS,
    data,
  };
}

export function saveWishlistsFailure(error) {
  return {
    type: SAVE_WISHLIST_FAILURE,
    error,
  };
}

//Product Search Suggetion
export function fetchProductSuggetionStart(data) {
  return {
    type: FETCH_PRODUCT_SUGGETION_START,
    data,
  };
}

export function fetchProductSuggetionSuccess(data) {
  return {
    type: FETCH_PRODUCT_SUGGETION_SUCCESS,
    data,
  };
}

export function fetchProductSuggetionFailure(error) {
  return {
    type: FETCH_PRODUCT_SUGGETION_FAILURE,
    error,
  }
}
