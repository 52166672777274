import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Cart.css";
import NumericInput from "react-numeric-input";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../Helper/NoDataFound";
import {
  fetchCartListStart,
  fetchGuestCartListStart,
  fetchGuestCartListSuccess,
  guestCartList,
  updateGuestCartListStart,
} from "../store/actions/CartsAction";
import CartItem from "./CartItem";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import NoOrdersFound from "../Helper/NoOrdersFound";
import NoCartItems from "../Helper/NoCartItems";

const CartIndex = (props) => {
  const navigate = useNavigate();

  const t = useTranslation();
  const [first, setFirst] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("userId")) props.dispatch(fetchCartListStart());
    else
      props.dispatch(
        guestCartList(
          localStorage.getItem("carts")
            ? JSON.parse(localStorage.getItem("carts"))
            : []
        )
      );
  }, []);

  useEffect(() => {
    if (props.guestCartList.length > 0) {
      if (first) {
        props.dispatch(
          fetchGuestCartListStart({
            carts: JSON.stringify(props.guestCartList),
          })
        );
      } else {
        props.dispatch(
          updateGuestCartListStart({
            carts: JSON.stringify(props.guestCartList),
          })
        );
      }
    } else {
      if (!first) {
        props.dispatch(fetchGuestCartListSuccess({}));
      }
    }
    setFirst(false);
  }, [props.guestCartList]);

  const updateGuestCartQty = (productId, qty) => {
    const newCart = props.guestCartList.map((product) =>
      product.product_id !== productId
        ? product
        : { product_id: productId, quantity: qty }
    );
    localStorage.setItem("carts", JSON.stringify(newCart));
    props.dispatch(guestCartList(newCart));
  };

  const removeGuestCart = (product_id) => {
    const newCart = props.guestCartList.filter(
      (product) => product.product_id !== product_id
    );
    localStorage.setItem("carts", JSON.stringify(newCart));
    props.dispatch(guestCartList(newCart));
  };

  const placeOrder = (e) => {
    e.preventDefault();
    if (Object.keys(props.profile.data).length > 0) {
      navigate("/checkout-address");
    } else {
      // localStorage.setItem('order', 'orderId')
      navigate("/login");
    }
  };

  return (
    <>
      <div className="cart-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="section-title-sec">
                <h3>{t("your_shopping_cart")}</h3>
              </div>
            </Col>
          </Row>
          {localStorage.getItem("userId") ? (
            <Row className="mt-3 gy-4 justify-content-center">
              <Col xl={8} md={12}>
                <div className="cart-box">
                  <div className="cart-header-sec">
                    <h4>
                      {t("product_details")}{" "}
                      <span>
                        (
                        {Object.keys(props.profile.data).length > 0
                          ? props.profile.data.cart_count
                          : 0}{" "}
                        {t("items")})
                      </span>
                    </h4>
                  </div>
                  <div className="cart-body-sec">
                    <div className="cart-list-item-box">
                      {props.cartList.loading ? (
                        <Skeleton count={2} height={200} />
                      ) : props.cartList.data.carts.length > 0 ? (
                        props.cartList.data.carts.map((cart, i) => (
                          <CartItem cart={cart} key={i} />
                        ))
                      ) : (
                        <NoCartItems />
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              {props.cartList.loading ? (
                <Col xl={4} md={12}>
                  <div className="cart-box">
                    <div className="cart-header-sec">
                      <h4>{t("order_details")}</h4>
                    </div>
                    <Skeleton height={200} />
                  </div>
                </Col>
              ) : props.cartList.data.carts.length > 0 ? (
                <Col xl={4} md={12}>
                  <div className="cart-box">
                    <div className="cart-header-sec">
                      <h4>{t("order_details")}</h4>
                    </div>
                    <div className="cart-body-sec">
                      <div className="cart-summary-info">
                        <div className="cart-summary-header">
                          <p>
                            {t("cart_total")}{" "}
                            <span>
                              {
                                props.cartList.data.user_cart_amount
                                  .cart_total_amount
                              }
                            </span>
                          </p>
                          <p>
                            {t("discount")}{" "}
                            <span>
                              -
                              {
                                props.cartList.data.user_cart_amount
                                  .cart_discount_amount
                              }
                            </span>
                          </p>
                        </div>
                        <div className="cart-summary-total-amount">
                          <h5>
                            {t("order_total")}{" "}
                            <span>
                              {
                                props.cartList.data.user_cart_amount
                                  .cart_checkout_amount
                              }
                            </span>
                          </h5>
                        </div>
                        <div className="cart-summary-btn-sec">
                          <Link
                            onClick={placeOrder}
                            to="#"
                            className="place-order-btn"
                          >
                            {t("place_order")}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="continue-shopping-btn-sec">
                      <Link to="/shop" className="continue-shopping-btn">
                        {t("continue_shopping")}
                      </Link>
                    </div>
                  </div>
                </Col>
              ) : null}
            </Row>
          ) : (
            <Row className="margin-top-lg gy-4 justify-content-center">
              <Col xl={8} md={12}>
                <div className="cart-box">
                  <div className="cart-header-sec">
                    <h4>
                      {t("product_details")}{" "}
                      <span>
                        ({props.guestCartList.length} {t("items")})
                      </span>
                    </h4>
                  </div>
                  <div className="cart-body-sec">
                    <div className="cart-list-item-box">
                      {props.guestCart.data.carts &&
                      props.guestCart.data.carts.length > 0 ? (
                        props.guestCart.data.carts.map((cart, i) => (
                          <div className="cart-list-card" key={i}>
                            <div className="cart-list-left-sec">
                              <div className="cart-list-img-sec">
                                <CustomLazyLoad
                                  src={cart.product.file}
                                  placeholderSrc={
                                    window.location.origin +
                                    "/images/loading.svg"
                                  }
                                  classes="cart-list-img"
                                />
                              </div>
                              <div className="cart-list-info">
                                <h4>{cart.product.name}</h4>
                                <p>
                                  {t("category")}{" "}
                                  <span>{cart.product.category_name}</span>
                                </p>
                                <p>
                                  {t("vol")}{" "}
                                  <span>
                                    {cart.product.gross_weight_formatted}
                                  </span>
                                </p>
                                <p>
                                  {t("qty")}
                                  <span>
                                    <NumericInput
                                      mobile
                                      className="form-control"
                                      min={1}
                                      value={cart.quantity}
                                      onChange={(val) =>
                                        updateGuestCartQty(
                                          cart.product.product_id,
                                          val
                                        )
                                      }
                                    />
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="cart-list-right-sec">
                              <div className="cart-list-amout-card">
                                <div className="cart-amount-info">
                                  <h4>
                                    {cart.product.selling_price_formatted}
                                  </h4>
                                  <p>
                                    {cart.product.discount_tag} |{" "}
                                    <del>
                                      {cart.product.original_price_formatted}
                                    </del>
                                  </p>
                                </div>
                                <div className="cart-action-btn-sec">
                                  <Button
                                    className="cart-remove-btn"
                                    onClick={() =>
                                      removeGuestCart(cart.product.product_id)
                                    }
                                  >
                                    {t("remove")}
                                  </Button>
                                  <Link to="/wishlist" className="wishlist-btn">
                                    {t("add_to_wishlist")}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <NoCartItems />
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              {props.guestCart.loading ? (
                <Col xl={4} md={12}>
                  <div className="cart-box">
                    <div className="cart-header-sec">
                      <h4>{t("order_details")}</h4>
                    </div>
                    <Skeleton height={200} />
                  </div>
                </Col>
              ) : props.guestCart.data.carts &&
                props.guestCart.data.carts.length > 0 ? (
                <Col xl={4} md={12}>
                  <div className="cart-box">
                    <div className="cart-header-sec">
                      <h4>{t("order_details")}</h4>
                    </div>
                    <div className="cart-body-sec">
                      <div className="cart-summary-info">
                        <div className="cart-summary-header">
                          <p>
                            {t("cart_total")}{" "}
                            <span>
                              {
                                props.guestCart.data.user_cart_amount
                                  .cart_total_amount
                              }
                            </span>
                          </p>
                          <p>
                            {t("discount")}{" "}
                            <span>
                              -
                              {
                                props.guestCart.data.user_cart_amount
                                  .cart_discount_amount
                              }
                            </span>
                          </p>
                        </div>
                        <div className="cart-summary-total-amount">
                          <h5>
                            {t("order_total")}{" "}
                            <span>
                              {
                                props.guestCart.data.user_cart_amount
                                  .cart_checkout_amount
                              }
                            </span>
                          </h5>
                        </div>
                        <div className="cart-summary-btn-sec">
                          <Link
                            onClick={placeOrder}
                            to="#"
                            className="place-order-btn"
                          >
                            {t("place_order")}
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="continue-shopping-btn-sec">
                      <Link to="/shop" className="continue-shopping-btn">
                        {t("continue_shopping")}
                      </Link>
                    </div>
                  </div>
                </Col>
              ) : null}
            </Row>
          )}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  cartList: state.carts.cartList,
  guestCartList: state.carts.guestCartList,
  guestCart: state.carts.guestCart,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(CartIndex));
