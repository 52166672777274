import {
  GET_CARD_TOKEN_START,
  GET_CARD_TOKEN_SUCCESS,
  GET_CARD_TOKEN_FAILURE,
  INITIATE_PAYMENT_START,
  INITIATE_PAYMENT_SUCCESS,
  INITIATE_PAYMENT_FAILURE,
  CHECK_PAYMENT_STATUS_START,
  CHECK_PAYMENT_STATUS_SUCCESS,
  CHECK_PAYMENT_STATUS_FAILURE
} from "./ActionConstant";


// Get Card Token
export function getCardTokenStart(data) {
  return {
    type: GET_CARD_TOKEN_START,
    data,
  };
}

export function getCardTokenSuccess(data) {
  return {
    type: GET_CARD_TOKEN_SUCCESS,
    data,
  };
}

export function getCardTokenFailure(error) {
  return {
    type: GET_CARD_TOKEN_FAILURE,
    error,
  };
}


//Payment Initiate
export function initiatePaymentStart(data) {
  return {
    type: INITIATE_PAYMENT_START,
    data,
  };
}

export function initiatePaymentSuccess(data) {
  return {
    type: INITIATE_PAYMENT_SUCCESS,
    data,
  };
}

export function initiatePaymentFailure(error) {
  return {
    type: INITIATE_PAYMENT_FAILURE,
    error,
  };
}

// Payment Status
export function checkPaymentStatusStart(data) {
  return {
    type: CHECK_PAYMENT_STATUS_START,
    data,
  };
}

export function checkPaymentStatusSuccess(data) {
  return {
    type: CHECK_PAYMENT_STATUS_SUCCESS,
    data
  };
}

export function checkPaymentStatusFailure(error) {
  return {
    type: CHECK_PAYMENT_STATUS_FAILURE,
    error,
  }
}