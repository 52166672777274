import React from "react";
import { Row, Col, Container, Form, Image, Button } from "react-bootstrap";
import { withTranslation, useTranslation } from "react-multi-lang";
import { Link, useLocation } from "react-router-dom";

const ProfileSideBar = () => {
  const t = useTranslation();
  const location = useLocation();

  return (
    <>
      <div className="profile-sidebar">
        <ul>
          <li className={location.pathname === "/profile" ? "active" : ""}>
            <Link to="/profile">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z" />
                </svg>
              </span>
              {t("peronal_details")}
            </Link>
          </li>
          <li className={location.pathname === "/my-order" ? "active" : ""}>
            <Link to="/my-order">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M509.5 184.6L458.9 32.8C452.4 13.2 434.1 0 413.4 0H272v192h238.7c-.4-2.5-.4-5-1.2-7.4zM240 0H98.6c-20.7 0-39 13.2-45.5 32.8L2.5 184.6c-.8 2.4-.8 4.9-1.2 7.4H240V0zM0 224v240c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V224H0z" />
                </svg>
              </span>
              {t("my_orders")}
            </Link>
          </li>
          <li
            className={location.pathname === "/my-order/cancel" ? "active" : ""}
          >
            <Link to="/my-order/cancel">
              <span>
                <svg
                  id="Layer_1"
                  enable-background="new 0 0 512 512"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path d="m236.94 233.335-230-96.842c-3.295-1.388-6.94 1.032-6.94 4.608v258.899c0 6.542 3.983 12.426 10.058 14.855l223.085 89.234c3.284 1.314 6.857-1.105 6.857-4.642v-261.504c0-2.011-1.206-3.827-3.06-4.608z" />
                    <path d="m257.94 205.823 222.315-93.606c3.996-1.682 4.103-7.306.174-9.139l-217.663-101.577c-4.289-2.001-9.243-2.001-13.532 0l-217.663 101.576c-3.929 1.833-3.822 7.457.174 9.139l222.315 93.606c1.24.523 2.64.523 3.88.001z" />
                    <path d="m400 256c42.31 0 80.411 18.348 106.779 47.499 1.843 2.038 5.221.743 5.221-2.004v-160.394c0-3.576-3.645-5.996-6.94-4.608l-230 96.842c-1.854.781-3.06 2.596-3.06 4.608v84.794c0 3.018 3.95 4.143 5.54 1.579 25.414-40.972 70.802-68.316 122.46-68.316z" />
                    <path d="m400 288c-61.757 0-112 50.243-112 112s50.243 112 112 112 112-50.243 112-112-50.243-112-112-112zm35.313 124.686c6.249 6.249 6.249 16.379 0 22.628-3.124 3.124-7.218 4.686-11.313 4.686s-8.189-1.562-11.313-4.686l-12.687-12.687-12.687 12.687c-3.124 3.124-7.218 4.686-11.313 4.686s-8.189-1.562-11.313-4.686c-6.249-6.249-6.249-16.379 0-22.628l12.686-12.686-12.686-12.686c-6.249-6.249-6.249-16.379 0-22.628 6.248-6.248 16.379-6.248 22.627 0l12.686 12.686 12.687-12.687c6.248-6.248 16.379-6.248 22.627 0 6.249 6.249 6.249 16.379 0 22.628l-12.687 12.687z" />
                  </g>
                </svg>
              </span>
              {t("cancelled_orders")}
            </Link>
          </li>
          <li
            className={
              location.pathname === "/order-transactions" ? "active" : ""
            }
          >
            <Link to="/order-transactions">
              <span>
                <svg
                  id="Capa_1"
                  enable-background="new 0 0 512 512"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m411 8.782v91.218h91.211z" />
                  <path d="m396 130c-8.284 0-15-6.716-15-15v-115h-215c-24.813 0-45 20.187-45 45v167.689c4.942-.448 9.943-.689 15-.689 51.128 0 96.897 23.376 127.186 60h162.814c8.284 0 15 6.716 15 15s-6.716 15-15 15h-143.052c9.376 18.296 15.422 38.565 17.363 60h125.689c8.284 0 15 6.716 15 15s-6.716 15-15 15h-125.689c-4.486 49.539-30.954 92.826-69.553 120h235.242c24.813 0 45-20.187 45-45v-337zm30 82h-220c-8.284 0-15-6.716-15-15s6.716-15 15-15h220c8.284 0 15 6.716 15 15s-6.716 15-15 15z" />
                  <path d="m136 242c-74.439 0-135 60.561-135 135s60.561 135 135 135 135-60.561 135-135-60.561-135-135-135zm40 150h-40c-8.284 0-15-6.716-15-15v-60c0-8.284 6.716-15 15-15s15 6.716 15 15v45h25c8.284 0 15 6.716 15 15s-6.716 15-15 15z" />
                </svg>
              </span>
              {t("order_transactions")}
            </Link>
          </li>
          <li
            className={location.pathname === "/manage-address" ? "active" : ""}
          >
            <Link to="/manage-address">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                  <path d="M575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6H511.8L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5L575.8 255.5z" />
                </svg>
              </span>
              {t("manage_address")}
            </Link>
          </li>
          {/* <li
            className={location.pathname === "/payment-method" ? "active" : ""}
          >
            <Link to="/payment-method">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                  <path d="M512 32C547.3 32 576 60.65 576 96V128H0V96C0 60.65 28.65 32 64 32H512zM576 416C576 451.3 547.3 480 512 480H64C28.65 480 0 451.3 0 416V224H576V416zM112 352C103.2 352 96 359.2 96 368C96 376.8 103.2 384 112 384H176C184.8 384 192 376.8 192 368C192 359.2 184.8 352 176 352H112zM240 384H368C376.8 384 384 376.8 384 368C384 359.2 376.8 352 368 352H240C231.2 352 224 359.2 224 368C224 376.8 231.2 384 240 384z" />
                </svg>
              </span>
              {t("payment")}
            </Link>
          </li>
          <li
            className={
              location.pathname === "/profile-wallet" ||
              location.pathname === "/wallet-add-money"
                ? "active"
                : ""
            }
          >
            <Link to="/profile-wallet">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M461.2 128H80c-8.84 0-16-7.16-16-16s7.16-16 16-16h384c8.84 0 16-7.16 16-16 0-26.51-21.49-48-48-48H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h397.2c28.02 0 50.8-21.53 50.8-48V176c0-26.47-22.78-48-50.8-48zM416 336c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z" />
                </svg>
              </span>
              {t("wallet")}
            </Link>
          </li>
          <li
            className={
              location.pathname === "/wallet-withdraw-money" ||
              location.pathname === "/withdraw"
                ? "active"
                : ""
            }
          >
            <Link to="/withdraw">
              <span>
                <svg
                  id="Layer_1"
                  height="26"
                  viewBox="0 0 26 26"
                  width="26"
                  data-name="Layer 1"
                  version="1.1"
                >
                  <defs id="defs9" />
                  <path
                    d="M 23,0 H 3 A 3.009,3.009 0 0 0 0,3 v 6 a 3.009,3.009 0 0 0 3,3 H 4 V 7 A 1,1 0 0 1 4,5 h 18 a 1,1 0 0 1 0,2 v 5 h 1 A 3.009,3.009 0 0 0 26,9 V 3 A 3.009,3.009 0 0 0 23,0 Z"
                    id="path2"
                  />
                  <path
                    d="m 6,23 a 3.009,3.009 0 0 0 3,3 h 8 a 3.009,3.009 0 0 0 3,-3 V 7 H 6 Z m 3.293,-7.707 a 1,1 0 0 1 1.414,0 L 12,16.586 V 12 a 1,1 0 0 1 2,0 v 4.586 l 1.293,-1.293 a 1,1 0 0 1 1.414,1.414 l -3,3 a 1,1 0 0 1 -1.414,0 l -3,-3 a 1,1 0 0 1 0,-1.414 z"
                    id="path4"
                  />
                </svg>
              </span>
              {t("withdraw")}
            </Link>
          </li> */}
          <li
            className={
              location.pathname === "/two-step-authentication" ? "active" : ""
            }
          >
            <Link to="/two-step-authentication">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" />
                </svg>
              </span>
              {t("two_step_auth")}
            </Link>
          </li>
          <li
            className={location.pathname === "/change-password" ? "active" : ""}
          >
            <Link to="/change-password">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z" />
                </svg>
              </span>
              {t("change_password")}
            </Link>
          </li>
          <li
            className={location.pathname === "/delete-account" ? "active" : ""}
          >
            <Link to="/delete-account">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M160 400C160 408.8 152.8 416 144 416C135.2 416 128 408.8 128 400V192C128 183.2 135.2 176 144 176C152.8 176 160 183.2 160 192V400zM240 400C240 408.8 232.8 416 224 416C215.2 416 208 408.8 208 400V192C208 183.2 215.2 176 224 176C232.8 176 240 183.2 240 192V400zM320 400C320 408.8 312.8 416 304 416C295.2 416 288 408.8 288 400V192C288 183.2 295.2 176 304 176C312.8 176 320 183.2 320 192V400zM317.5 24.94L354.2 80H424C437.3 80 448 90.75 448 104C448 117.3 437.3 128 424 128H416V432C416 476.2 380.2 512 336 512H112C67.82 512 32 476.2 32 432V128H24C10.75 128 0 117.3 0 104C0 90.75 10.75 80 24 80H93.82L130.5 24.94C140.9 9.357 158.4 0 177.1 0H270.9C289.6 0 307.1 9.358 317.5 24.94H317.5zM151.5 80H296.5L277.5 51.56C276 49.34 273.5 48 270.9 48H177.1C174.5 48 171.1 49.34 170.5 51.56L151.5 80zM80 432C80 449.7 94.33 464 112 464H336C353.7 464 368 449.7 368 432V128H80V432z" />
                </svg>
              </span>
              {t("delete_account")}
            </Link>
          </li>
        </ul>
      </div>
      <div className="profile-sidebar mobile-profile">
        <ul>
          <li
            className={location.pathname === "/mobile-profile" ? "active" : ""}
          >
            <Link to="/mobile-profile">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z" />
                </svg>
              </span>
              {t("personal_details")}
            </Link>
          </li>
          <li className={location.pathname === "/my-order" ? "active" : ""}>
            <Link to="/my-order">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M509.5 184.6L458.9 32.8C452.4 13.2 434.1 0 413.4 0H272v192h238.7c-.4-2.5-.4-5-1.2-7.4zM240 0H98.6c-20.7 0-39 13.2-45.5 32.8L2.5 184.6c-.8 2.4-.8 4.9-1.2 7.4H240V0zM0 224v240c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V224H0z" />
                </svg>
              </span>
              {t("my_orders")}
            </Link>
          </li>
          <li
            className={location.pathname === "/my-order/cancel" ? "active" : ""}
          >
            <Link to="/my-order/cancel">
              <span>
                <svg
                  id="Layer_1"
                  enable-background="new 0 0 512 512"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path d="m236.94 233.335-230-96.842c-3.295-1.388-6.94 1.032-6.94 4.608v258.899c0 6.542 3.983 12.426 10.058 14.855l223.085 89.234c3.284 1.314 6.857-1.105 6.857-4.642v-261.504c0-2.011-1.206-3.827-3.06-4.608z" />
                    <path d="m257.94 205.823 222.315-93.606c3.996-1.682 4.103-7.306.174-9.139l-217.663-101.577c-4.289-2.001-9.243-2.001-13.532 0l-217.663 101.576c-3.929 1.833-3.822 7.457.174 9.139l222.315 93.606c1.24.523 2.64.523 3.88.001z" />
                    <path d="m400 256c42.31 0 80.411 18.348 106.779 47.499 1.843 2.038 5.221.743 5.221-2.004v-160.394c0-3.576-3.645-5.996-6.94-4.608l-230 96.842c-1.854.781-3.06 2.596-3.06 4.608v84.794c0 3.018 3.95 4.143 5.54 1.579 25.414-40.972 70.802-68.316 122.46-68.316z" />
                    <path d="m400 288c-61.757 0-112 50.243-112 112s50.243 112 112 112 112-50.243 112-112-50.243-112-112-112zm35.313 124.686c6.249 6.249 6.249 16.379 0 22.628-3.124 3.124-7.218 4.686-11.313 4.686s-8.189-1.562-11.313-4.686l-12.687-12.687-12.687 12.687c-3.124 3.124-7.218 4.686-11.313 4.686s-8.189-1.562-11.313-4.686c-6.249-6.249-6.249-16.379 0-22.628l12.686-12.686-12.686-12.686c-6.249-6.249-6.249-16.379 0-22.628 6.248-6.248 16.379-6.248 22.627 0l12.686 12.686 12.687-12.687c6.248-6.248 16.379-6.248 22.627 0 6.249 6.249 6.249 16.379 0 22.628l-12.687 12.687z" />
                  </g>
                </svg>
              </span>
              {t("cancelled_orders")}
            </Link>
          </li>
          <li
            className={
              location.pathname === "/order-transactions" ? "active" : ""
            }
          >
            <Link to="/order-transactions">
              <span>
                <svg
                  id="Capa_1"
                  enable-background="new 0 0 512 512"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m411 8.782v91.218h91.211z" />
                  <path d="m396 130c-8.284 0-15-6.716-15-15v-115h-215c-24.813 0-45 20.187-45 45v167.689c4.942-.448 9.943-.689 15-.689 51.128 0 96.897 23.376 127.186 60h162.814c8.284 0 15 6.716 15 15s-6.716 15-15 15h-143.052c9.376 18.296 15.422 38.565 17.363 60h125.689c8.284 0 15 6.716 15 15s-6.716 15-15 15h-125.689c-4.486 49.539-30.954 92.826-69.553 120h235.242c24.813 0 45-20.187 45-45v-337zm30 82h-220c-8.284 0-15-6.716-15-15s6.716-15 15-15h220c8.284 0 15 6.716 15 15s-6.716 15-15 15z" />
                  <path d="m136 242c-74.439 0-135 60.561-135 135s60.561 135 135 135 135-60.561 135-135-60.561-135-135-135zm40 150h-40c-8.284 0-15-6.716-15-15v-60c0-8.284 6.716-15 15-15s15 6.716 15 15v45h25c8.284 0 15 6.716 15 15s-6.716 15-15 15z" />
                </svg>
              </span>
              {t("order_transactions")}
            </Link>
          </li>
          <li
            className={location.pathname === "/manage-address" ? "active" : ""}
          >
            <Link to="/manage-address">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                  <path d="M575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6H511.8L512.5 447.7C512.5 450.5 512.3 453.1 512 455.8V472C512 494.1 494.1 512 472 512H456C454.9 512 453.8 511.1 452.7 511.9C451.3 511.1 449.9 512 448.5 512H392C369.9 512 352 494.1 352 472V384C352 366.3 337.7 352 320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5L575.8 255.5z" />
                </svg>
              </span>
              {t("manage_address")}
            </Link>
          </li>
          <li
            className={location.pathname === "/payment-method" ? "active" : ""}
          >
            <Link to="/payment-method">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                  <path d="M512 32C547.3 32 576 60.65 576 96V128H0V96C0 60.65 28.65 32 64 32H512zM576 416C576 451.3 547.3 480 512 480H64C28.65 480 0 451.3 0 416V224H576V416zM112 352C103.2 352 96 359.2 96 368C96 376.8 103.2 384 112 384H176C184.8 384 192 376.8 192 368C192 359.2 184.8 352 176 352H112zM240 384H368C376.8 384 384 376.8 384 368C384 359.2 376.8 352 368 352H240C231.2 352 224 359.2 224 368C224 376.8 231.2 384 240 384z" />
                </svg>
              </span>
              {t("payment")}
            </Link>
          </li>
          <li
            className={
              location.pathname === "/profile-wallet" ||
              location.pathname === "/wallet-add-money"
                ? "active"
                : ""
            }
          >
            <Link to="/profile-wallet">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M461.2 128H80c-8.84 0-16-7.16-16-16s7.16-16 16-16h384c8.84 0 16-7.16 16-16 0-26.51-21.49-48-48-48H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h397.2c28.02 0 50.8-21.53 50.8-48V176c0-26.47-22.78-48-50.8-48zM416 336c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z" />
                </svg>
              </span>
              {t("wallet")}
            </Link>
          </li>
          <li
            className={
              location.pathname === "/two-step-authentication" ? "active" : ""
            }
          >
            <Link to="/two-step-authentication">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z" />
                </svg>
              </span>
              {t("two_step_auth")}
            </Link>
          </li>
          <li
            className={location.pathname === "/change-password" ? "active" : ""}
          >
            <Link to="/change-password">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z" />
                </svg>
              </span>
              {t("change_password")}
            </Link>
          </li>
          <li
            className={location.pathname === "/delete-account" ? "active" : ""}
          >
            <Link to="/delete-account">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M160 400C160 408.8 152.8 416 144 416C135.2 416 128 408.8 128 400V192C128 183.2 135.2 176 144 176C152.8 176 160 183.2 160 192V400zM240 400C240 408.8 232.8 416 224 416C215.2 416 208 408.8 208 400V192C208 183.2 215.2 176 224 176C232.8 176 240 183.2 240 192V400zM320 400C320 408.8 312.8 416 304 416C295.2 416 288 408.8 288 400V192C288 183.2 295.2 176 304 176C312.8 176 320 183.2 320 192V400zM317.5 24.94L354.2 80H424C437.3 80 448 90.75 448 104C448 117.3 437.3 128 424 128H416V432C416 476.2 380.2 512 336 512H112C67.82 512 32 476.2 32 432V128H24C10.75 128 0 117.3 0 104C0 90.75 10.75 80 24 80H93.82L130.5 24.94C140.9 9.357 158.4 0 177.1 0H270.9C289.6 0 307.1 9.358 317.5 24.94H317.5zM151.5 80H296.5L277.5 51.56C276 49.34 273.5 48 270.9 48H177.1C174.5 48 171.1 49.34 170.5 51.56L151.5 80zM80 432C80 449.7 94.33 464 112 464H336C353.7 464 368 449.7 368 432V128H80V432z" />
                </svg>
              </span>
              {t("delete_account")}
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default withTranslation(ProfileSideBar);
