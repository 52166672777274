import React, { useState, useEffect } from "react";
import ProfileSideBar from "./ProfileSideBar";
import {
  Row,
  Col,
  Container,
  Form,
  Image,
  Button,
  Dropdown,
} from "react-bootstrap";
import { fetchOrdersListStart } from "../store/actions/OrdersAction";
import { Link } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import down from "./down-arrow.svg";
import up from "./up-arrow.svg";
import { connect } from "react-redux";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import CommonCenterLoader from "../Helper/CommonCenterLoader";
import { useParams } from "react-router-dom";
import { apiUrl } from "../Environment";
import NoOrdersFound from "../Helper/NoOrdersFound";

const MyOrder = (props) => {
  const params = useParams();
  const [isVisible, setIsVisible] = useState(false);
  const t = useTranslation();

  const fetchMoreWithdraw = () => {
    let data = {
      skip: props.orderList.data.orders.length,
      take: 12,
    };
    if (params.status === "cancel") data = { ...data, status: 6 };
    props.dispatch(fetchOrdersListStart(data));
  };

  useEffect(() => {
    let data = {
      skip: 0,
      take: 12,
    };
    if (params.status === "cancel") data = { ...data, status: 6 };
    props.dispatch(fetchOrdersListStart(data));
  }, [params.status]);

  const download = (order) => {
    const url = apiUrl + "orders_invoice";
    let formData = new FormData();
    formData.append("id", localStorage.getItem("userId"));
    formData.append("token", localStorage.getItem("accessToken"));
    formData.append("order_unique_id", order.order_unique_id);
    formData.append("invoice_via", "download");
    fetch(url, {
      method: "POST",
      headers: {},
      body: formData,
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `order_${order.order_unique_id}.pdf`); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col xl={3} md={12} lg={4}>
              <div className="new-mobile-section">
                <ProfileSideBar />
              </div>
            </Col>
            <Col xl={9} md={12} lg={8}>
              <div className="profile-details-sec">
                <div className="profile-details-title">
                  <h4>
                    {params.status === "cancel"
                      ? t("cancelled_orders")
                      : t("my_orders")}
                  </h4>
                </div>
                <div className="wallet-table-sec mt-4">
                  {props.orderList.loading ? (
                    <div>
                      <Skeleton height={80} />
                      <Skeleton height={80} />
                      <Skeleton height={80} />
                    </div>
                  ) : (
                    <div className="my-order-sec">
                      {props.orderList.data.orders &&
                      props.orderList.data.orders.length > 0 ? (
                        <InfiniteScroll
                          dataLength={props.orderList.data.orders.length}
                          next={fetchMoreWithdraw}
                          hasMore={
                            props.orderList.data.orders.length <
                            props.orderList.data.total
                          }
                          loader={<CommonCenterLoader />}
                        >
                          <div className="profile-details-title">
                            {/* <h4>{params.status === "cancel" ? t("cancelled_orders") : t("my_orders")}</h4> */}
                            <p>
                              {t("total_orders")}{" "}
                              {props.orderList.data.total_orders}
                            </p>
                          </div>
                          {props.orderList.data.orders.map((order, i) => (
                            <div className="delivery-status" key={i}>
                              <div
                                className="deliver-status-card point"
                                onClick={() =>
                                  setIsVisible(isVisible === i ? false : i)
                                }
                              >
                                <h4>
                                  {t("order_placed")}
                                  <span>{order.order_payment.paid_date}</span>
                                </h4>
                                <h4>
                                  {t("total")}{" "}
                                  <span>
                                    {order.order_payment.total_formatted}
                                  </span>
                                </h4>
                                <h4>
                                  {t("status")}{" "}
                                  <span>{order.status_formatted}</span>
                                </h4>
                                <h4>
                                  {t("order_id")}{" "}
                                  <span>{order.order_unique_id}</span>
                                </h4>
                                <Image
                                  src={isVisible !== i ? down : up}
                                  type="image/png"
                                />
                              </div>

                              {isVisible === i && (
                                <div className="step-1">
                                  {order.order_payment.payment_mode == "ADMIN" && order.status < 3 && (
                                  <h5 className="order-notes">
                                    Note:
                                    <span>
                                      We'll contact you soon for payment and
                                      order updates.
                                    </span>
                                  </h5>
                                  )}
                                  <div className="delivery-address">
                                    <div className="delivery-payment delivery-title">
                                      <h3>{t("payment_details")}</h3>
                                      <h4>
                                        {t("sub_total")}{" "}
                                        <span>{order.sub_total_formatted}</span>
                                      </h4>
                                      {/* <h4>
                                  {t("coupon_discount")} <span>$3.00</span>
                                        </h4> */}
                                      <h4>
                                        {t("order_total")}{" "}
                                        <span>
                                          {order.order_payment.total_formatted}
                                        </span>
                                      </h4>
                                      <h4>
                                        {t("payment_method")}{" "}
                                        <span>
                                          {order.order_payment.payment_mode}
                                        </span>
                                      </h4>
                                    </div>
                                    <div className="deliver-shipping delivery-title">
                                      <h3>{t("shipping_details")}</h3>
                                      <h4>{order.delivery_address.name}</h4>
                                      <p>
                                        {order.delivery_address.address}
                                        <br /> {order.delivery_address.landmark}
                                        <br /> {
                                          order.delivery_address.state
                                        }, {order.delivery_address.pincode}{" "}
                                        <br />
                                        {order.delivery_address.contact_number}
                                      </p>
                                    </div>
                                    <div className="deliver-coupon">
                                      <Button
                                        className="download-receipt-btn"
                                        onClick={() => download(order)}
                                      >
                                        {t("get_invoice")}
                                      </Button>
                                      <Link
                                        to={"/contact-us"}
                                        className="wishlist-btn"
                                      >
                                        {t("need_help")}
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="deliver-status-sec mt-4">
                                    <h3>Deliverd On 07 Aug, 2022</h3>
                                    <div className="delivery-status-dropdown">
                                      <Link
                                        to={`/order-tracking/${order.order_unique_id}`}
                                        className="search-btn"
                                      >
                                        {t("track_shipment")}
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="other-product-details mt-4">
                                    {order.order_products.map(
                                      (order_product, i) => (
                                        <div className="other-product-box">
                                          <div className="checkout-product-box">
                                            <div className="checkout-product-img">
                                              <Image
                                                src={order_product.product.file}
                                                style={{
                                                  width: "141px",
                                                  height: "144px",
                                                }}
                                                type="image/png"
                                              />
                                            </div>
                                            <div className="checkout-product-content">
                                              <>
                                                <h4>
                                                  {order_product.product.name}
                                                </h4>
                                              </>

                                              <p>
                                                {t("qty")}
                                                <span>
                                                  {order_product.quantity}
                                                </span>
                                              </p>
                                              <h5>
                                                {
                                                  order_product.product
                                                    .selling_price_formatted
                                                }
                                                <span>
                                                  {
                                                    order_product.product
                                                      .original_price_formatted
                                                  }
                                                </span>
                                              </h5>
                                            </div>
                                          </div>
                                          <div className="other-product-view">
                                            <Link
                                              to=""
                                              className="wishlist-btn"
                                            >
                                              {t("write_product_view")}
                                            </Link>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </InfiniteScroll>
                      ) : (
                        <>
                          <NoOrdersFound />
                          <div className="continue-shopping-btn-sec text-center">
                            <Link to="/shop" className="continue-shopping-btn">
                              {t("continue_shopping")}
                            </Link>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Col>
            {/* <Col xl={9} md={12} lg={8}>
              <div className="my-order-sec">
                <div className="profile-details-title">
                  <h4>My Orders</h4>
                  <p>Total 2 orders</p>
                </div>
                <div className="my-order-box left-border-color">
                  <div className="order-details">
                    <h5>
                      <span>
                        {" "}
                        <Image
                          src={window.location.origin + "/images/dot.png"}
                          type="image/png"
                        />
                      </span>
                      Dispatched
                    </h5>
                    <h4>
                      <span>Order ID :</span>6132164651654651651
                    </h4>
                  </div>
                  <div className="order-product-details-sec mt-4">
                    <div className="product-img">
                      <div className="order-product-img">
                        <Image
                          src={window.location.origin + "/images/item-1.png"}
                          type="image/png"
                        />
                      </div>
                      <div className="order-product-details">
                        <h5>1000mg CBD Sport Cream</h5>
                        <h4>
                          1,430.00 $ <span>QTY-2</span>
                        </h4>
                        <div className="invoice-details">
                          <Link to="">
                            <span>
                              <Image
                                src={
                                  window.location.origin +
                                  "/images/file-invoice.svg"
                                }
                                type="image/png"
                              />
                            </span>
                            Get Invoice
                          </Link>
                          <Link to=""> Need Help?</Link>
                        </div>
                      </div>
                    </div>
                    <div className="product-tracking-details">
                      <Link to="" className="wishlist-btn">
                        Track Order
                      </Link>
                      <Link to="" className="order-btn">
                        Order details
                      </Link>
                      <Link to="" className="edit-order-btn">
                        <span>
                          <Image
                            src={
                              window.location.origin + "/images/edit-solid.svg"
                            }
                            type="image/png"
                          />
                        </span>
                        Edit Order
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="my-order-box mt-4">
                  <div className="order-details">
                    <h5>
                      <span>
                        {" "}
                        <Image
                          src={window.location.origin + "/images/dot.png"}
                          type="image/png"
                        />
                      </span>
                      Delivered on 12-03-2022
                    </h5>
                    <h4>
                      <span>Order ID :</span>6132164651654651651
                    </h4>
                  </div>
                  <div className="order-product-details-sec mt-4">
                    <div className="product-img">
                      <div className="order-product-img order-bg-color">
                        <Image
                          src={window.location.origin + "/images/item-1.png"}
                          type="image/png"
                        />
                      </div>
                      <div className="order-product-details">
                        <h5>1000mg CBD Sport Cream</h5>
                        <h4>
                          1,430.00 $ <span>QTY-2</span>
                        </h4>
                        <div className="invoice-details">
                          <Link to="">
                            <span>
                              <Image
                                src={
                                  window.location.origin +
                                  "/images/file-invoice.svg"
                                }
                                type="image/png"
                              />
                            </span>
                            Get Invoice
                          </Link>
                          <Link to=""> Need Help?</Link>
                        </div>
                      </div>
                    </div>
                    <div className="product-tracking-details">
                      <Link to="" className="order-btn">
                        Order details
                      </Link>
                      <Link to="" className="edit-order-btn">
                        <span>
                          <Image
                            src={
                              window.location.origin + "/images/edit-solid.svg"
                            }
                            type="image/png"
                          />
                        </span>
                        Edit Order
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  orderList: state.orders.orderList,
  orderDetails: state.orders.orderDetails,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(MyOrder));
