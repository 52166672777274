import React, { useEffect, useState } from 'react';
import { withTranslation, useTranslation } from "react-multi-lang";
import { Image, Button } from 'react-bootstrap';
import { useDebounce } from 'use-debounce';
import {
  useAccount,
  useBalance,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { ConnectKitButton } from "connectkit";
import { ethers } from 'ethers';
import Token from "../../abis/Token.json";
import Web3 from "web3";
import { connect } from 'react-redux';

const CheckoutWalletButton = (props) => {
  const t = useTranslation();
  const { address, connector, isConnected, isConnecting } = useAccount();
  const contractAddress = "0xA72Eb0986c2AEACFD9A8CDcD7440579869751CFE";
  const adminWalletAddress = "0x9a8Df595Af38106365B6e833152DdF6ED77A1BE1";
  const balance = useBalance({
    addressOrName: address,
    token: contractAddress,
    watch: true,
  });

  const web3 = new Web3(window.ethereum);
  const decimals = web3.utils.toBN(18);
  let allowed_tokens = props.cartFinalAmount;

  // Amount of token
  const tokenAmount = web3.utils.toBN(allowed_tokens);

  // Amount as Hex - contract.methods.transfer(toAddress, tokenAmountHex).encodeABI();
  const tokenAmountHex = "0x" + tokenAmount.mul(web3.utils.toBN(10).pow(decimals)).toString("hex");

  //Approve
  const approveConfig = usePrepareContractWrite({
    addressOrName: contractAddress,
    contractInterface: Token.abi,
    functionName: 'approve',
    args: [address, tokenAmountHex],
  });
  const approveData = useContractWrite(approveConfig.config);
  const approveWait = useWaitForTransaction({
    hash: approveData.data?.hash,
    onSuccess(data) {
      console.log('Success', data);
      paymentData.write();
    },
    onError(error) {
      console.log('Error', error);
      setIsLoading(false);
    }
  });

  //Payment
  const paymentConfig = usePrepareContractWrite({
    addressOrName: contractAddress,
    contractInterface: Token.abi,
    functionName: 'transferFrom',
    args: [address, adminWalletAddress, tokenAmountHex],
    overrides: {
      gasPrice: ethers.utils.parseUnits("20", "gwei"),
      gasLimit: 200000,
    },
  });
  const paymentData = useContractWrite({
    ...paymentConfig.config,
    onError(error) {
      console.log('Error', error);
      setIsLoading(false);
    },
    onSuccess(data) {
      console.log('Success', data);
      props.onFormSubmit("CRYPTO", {
        wallet_address: address,
        transaction_hash: data.hash,
        currency: "PSCN",
      });
      setIsLoading(false);
    },
  });

  const [isLoading, setIsLoading] = useState(false);


  return (
    <div className="payment-radio-btn label-bg dropdown-wallet mt-3">
      <label
        name="plan"
        className="payment-add-money-wallet"
      >
        <ConnectKitButton.Custom>
          {({ isConnected, isConnecting, show, hide, address, ensName }) => {
            return (
              <p onClick={() => show()}>
                <Image
                  src={
                    window.location.origin + "/images/wallet.svg"
                  }
                  type="image/png"
                />
                <span>
                  {address.substr(0, 6)}
                  ...
                  {address.substr(address.length - 4)}
                </span>
              </p>
            );
          }}
        </ConnectKitButton.Custom>
        <p>{balance.isFetched && balance.data?.formatted + " " + balance.data?.symbol}</p>
        <Button
          className="address-button"
          disabled={isLoading || props.ordersCheckout.buttonDisable}
          onClick={() => {
            approveData.write?.();
            setIsLoading(true);
          }}
        >{isLoading
          ? "Loading"
          : props.ordersCheckout.loadingButtonContent
            ? props.ordersCheckout.loadingButtonContent
            : t("checkout")}</Button>
      </label>
    </div>
  );
}

const mapStateToPros = (state) => ({
  ordersCheckout: state.orders.ordersCheckout,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(CheckoutWalletButton));