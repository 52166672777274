import React from "react"

const CommonCenterLoader = () => (
    <div className="text-center mt-5">
        {/* <img style={{ width: "5em", height: "5em" }} src={window.location.origin + "/images/small-loader.svg"} /> */}
        <svg style={{ margin: "auto", background: "none", display: "block", shapeRendering: "auto",width: "5em", height: "5em" }} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <circle cx="50" cy="50" r="29" stroke-width="5" stroke="#f76b1c" stroke-dasharray="45.553093477052 45.553093477052" fill="none" stroke-linecap="round">
                <animateTransform attributeName="transform" type="rotate" dur="1.25s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
            </circle>
            <circle cx="50" cy="50" r="23" stroke-width="5" stroke="#d65a14" stroke-dasharray="36.12831551628262 36.12831551628262" stroke-dashoffset="36.12831551628262" fill="none" stroke-linecap="round">
                <animateTransform attributeName="transform" type="rotate" dur="1.25s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;-360 50 50"></animateTransform>
            </circle>
        </svg>
    </div>
)

export default CommonCenterLoader;