import { apiConstants } from "../Constant/constants";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Image,
  Button,
  Tab,
  Nav,
  FormControl,
} from "react-bootstrap";
import { withTranslation, useTranslation } from "react-multi-lang";
import {
  saveDeliveryAddressesStart,
  fetchDeliveryAddressesListStart,
} from "../store/actions/DeliveryAction";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";

const AddressSubmitForm = ({ isModal = false, ...props }) => {
  const t = useTranslation();
  const [address, setAddress] = useState("");
  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    contact_number: Yup.string().required(t("required")).min(5, t("min_10")),
    state: Yup.string().required(t("required")),
    pincode: Yup.string().required(t("required")),
    // landmark: Yup.string().required(t("required")),
    address: Yup.string().required(t("required")),
  });

  const handleSubmit = (values) => {
    props.dispatch(
      saveDeliveryAddressesStart(
        props.editAddress.delivery_address_id
          ? {
            ...values,
            delivery_address_id: props.editAddress.delivery_address_id,
          }
          : values
      )
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.deliveryAddressesSave.loading &&
      Object.keys(props.deliveryAddressesSave.data).length > 0
    ) {
      if (!isModal) {
        props.setActiveTab("saved_billing");
        props.dispatch(fetchDeliveryAddressesListStart());
      }
    }
    setSkipRender(false);
  }, [props.deliveryAddressesSave]);

  const formRef = useRef();
  let autocomplete;

  const renderAutoComplete = () => {
    const { google } = props;
    if (!google) {
      console.log("asdfsadfasdfno");
      return;
    }

    autocomplete = new google.maps.places.Autocomplete(autocomplete, {
      types: ["geocode"],
    });

    const extractAddress = (location) => {
      const addresses = {
        city: "",
        state: "",
        zip: "",
        country: "",
        plain() {
          const city = this.city ? this.city + ", " : "";
          const zip = this.zip ? this.zip + ", " : "";
          const state = this.state ? this.state + ", " : "";
          return city + zip + state + this.country;
        },
      };

      if (!Array.isArray(location?.address_components)) {
        return addresses;
      }

      location.address_components.forEach((component) => {
        const types = component.types;
        const value = component.long_name;

        if (types.includes("locality")) {
          addresses.city = value;
        }

        if (types.includes("administrative_area_level_1")) {
          addresses.state = value;
        }

        if (types.includes("administrative_area_level_2")) {
          addresses.district = value;
        }

        if (types.includes("postal_code")) {
          addresses.zip = value;
        }

        if (types.includes("country")) {
          addresses.country = value;
        }
      });

      return addresses;
    };

    const onChangeAddress = (autocomplete) => {
      const place = autocomplete.getPlace();
      setAddress(extractAddress(place));
    };

    autocomplete.addListener("place_changed", () => {
      onChangeAddress(autocomplete);
      const location = autocomplete.getPlace();
      console.log("location", location)
      const extractedLocation = extractAddress(location);
      if (!location.geometry) return;
      let full_address = "";
      location.address_components.map(
        (address) =>
        (address.types.includes("street_number") ||
          address.types.includes("route") ||
          address.types.includes("sublocality_level_1") ||
          address.types.includes("sublocality") ?
          full_address =
          full_address == ""
            ? address.long_name
            : full_address + "," + address.long_name
          : full_address)
      );

      formRef.current.setFieldValue("address", full_address);
      formRef.current.setFieldValue("city", extractedLocation.city);
      formRef.current.setFieldValue("state", extractedLocation.state);
      formRef.current.setFieldValue("pincode", extractedLocation.zip);
    });
  };

  const handleName = (value) => {
    formRef.current.setFieldValue("name", value);
  };

  return (
    <div className="new-address-sec mt-4">
      <div className="login-form">
        <Formik
          initialValues={{
            name: props.editAddress.name ? props.editAddress.name : "",
            contact_number: props.editAddress.contact_number
              ? props.editAddress.contact_number
              : "",
            city: props.editAddress.city ? props.editAddress.city : "",
            state: props.editAddress.state ? props.editAddress.state : "",
            pincode: props.editAddress.pincode ? props.editAddress.pincode : "",
            // landmark: props.editAddress.landmark ? props.editAddress.landmark : "",
            address: props.editAddress.address ? props.editAddress.address : "",
            latitude: "",
            longitude: "",
          }}
          innerRef={formRef}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
          enableReinitialize={true}
        >
          <FORM>
            <Row>
              <Col md={12} xl={6} lg={6}>
                <Form.Group
                  className="mb-4 select-address-type"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("name")}*</Form.Label>
                  <Field
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder={t("address_choose")}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="name"
                    className="text-danger"
                  />
                  {/* <div className="select-address-type-btn">
                    <Button
                      className="text-btn"
                      type="button"
                      onClick={() => handleName("Home")}
                    >
                      {t("home")}
                    </Button>
                    <Button
                      className="text-btn"
                      type="button"
                      onClick={() => handleName("Work")}
                    >
                      {t("work")}
                    </Button>
                    <Button
                      className="text-btn"
                      type="button"
                      onClick={() => handleName("Others")}
                    >
                      {t("others")}
                    </Button>
                  </div> */}
                </Form.Group>
              </Col>
              <Col md={12} xl={6} lg={6}>
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("contact_number")}*</Form.Label>
                  <Field
                    type="number"
                    className="form-control"
                    name="contact_number"
                    placeholder={t("contact_placeholder")}
                    min="0"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="contact_number"
                    className="text-danger"
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group controlId="formBasicEmail" className="mb-4">
                  <Form.Label className="m-0">{t("location")}</Form.Label>{" "}
                  {/* <Form.Text className="text-muted">
                    {t("your_location_note")}
                  </Form.Text> */}
                  <p className="location-text mb-3">
                    {t("your_location_note")}
                  </p>
                  <FormControl
                    className="form-control"
                    aria-describedby="basic-addon2"
                    onFocus={renderAutoComplete}
                    ref={(ref) => (autocomplete = ref)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>{t("street_address")}*</Form.Label>
                  <Field
                    type="text"
                    className="form-control"
                    name="address"
                    placeholder={t("address_placeholder")}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="address"
                    className="text-danger"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label>{t("city")}*</Form.Label>
                  <Field
                    type="text"
                    className="form-control"
                    name="city"
                    placeholder={t("city_placeholder")}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="city"
                    className="text-danger"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("state")}*</Form.Label>
                  <Field
                    type="text"
                    className="form-control"
                    name="state"
                    placeholder={t("state_placeholder")}
                    min="0"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="state"
                    className="text-danger"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("zipcode")}*</Form.Label>
                  <Field
                    type="number"
                    className="form-control"
                    name="pincode"
                    placeholder={t("zipcode_placeholder")}
                    min="0"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="pincode"
                    className="text-danger"
                  />
                </Form.Group>
              </Col>
              {/* <Col md={6}>
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>{t("landmark")}*</Form.Label>
                  <Field
                    type="text"
                    className="form-control"
                    name="landmark"
                    placeholder={t("landmark_placeholder")}
                    min="0"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="landmark"
                    className="text-danger"
                  />
                </Form.Group>
              </Col> */}

              {/* <Col md={12}>
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>{t("address_area")}*</Form.Label>
                  <Field
                    as="textarea"
                    className="form-control"
                    name="address"
                    placeholder={t("address_placeholder")}
                    min="0"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="address"
                    className="text-danger"
                  />
                </Form.Group>
              </Col> */}
            </Row>
            <Form.Group
              className="mb-3 form-check-custom"
              controlId="formBasicCheckbox"
            >
              <Form.Check type="checkbox" label={t("use_as_default_add")} />
            </Form.Group>
            <div className="default-btn-sec">
              <Button
                type="submit"
                className="subscribe-btn"
                disabled={props.deliveryAddressesSave.buttonDisable}
              >
                {props.deliveryAddressesSave.loadingButtonContent
                  ? props.deliveryAddressesSave.loadingButtonContent
                  : t("save")}
              </Button>
            </div>
          </FORM>
        </Formik>
      </div>
    </div>
  );
};

const mapStateToPros = (state) => ({
  deliveryAddressesSave: state.delivery.deliveryAddressesSave,
  deliveryAddressesList: state.delivery.deliveryAddressesList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

const connector = connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(AddressSubmitForm));

export default GoogleApiWrapper({
  apiKey: apiConstants.google_api_key,
})(connector);

// export default (connector)
