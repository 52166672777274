import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Image, Button } from "react-bootstrap";
import * as Yup from "yup";
import { userLoginStart, userRegisterStart } from "../store/actions/UserAction";
import configuration from "react-global-configuration";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./Auth.css";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import SocialButton from "../Helper/SocialButton";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";

const REDIRECT_URI = window.location.href;

const LoginIndex = (props) => {
  const t = useTranslation();
  const [skipRender, setSkipRender] = useState(true);
  const [loginPasswordVisible, setLoginPasswordVisible] = useState(false);

  const navigate = useNavigate();

  const loginSchema = Yup.object().shape({
    email: Yup.string().email(t("invalid_email")).required(t("email_required")),
    password: Yup.string()
      .required(t("password_required"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("must_have_6_characters")),
  });

  const handleLogin = (values) => {
    props.dispatch(userLoginStart(values));
  };

  const handleFacebookLogin = (response) => {
    console.log("handleFacebookLogin", response);
    const emailAddress =
      response.email === undefined || response.email === null
        ? response.id + "@facebook.com"
        : response.email;
    props.dispatch(
      userRegisterStart({
        name: response.name,
        first_name: response.first_name ? response.first_name : "",
        last_name: response.last_name ? response.last_name : "",
        email: emailAddress,
        social_unique_id: response.userID,
        picture: response.picture ? response.picture.data.url : "",
        login_by: "facebook",
      })
    );
  };

  const handleGoogleLogin = (response) => {
    console.log("handleGoogleLogin", response);
    props.dispatch(
      userRegisterStart({
        name: response.name,
        email: response.email,
        first_name: response.given_name ? response.given_name : "",
        last_name: response.family_name ? response.family_name : "",
        social_unique_id: response.sub,
        picture: response.picture,
        login_by: "google",
      })
    );
  };

  const handleSocialLoginFailure = (err) => {
    console.error(err);
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.login.loading &&
      Object.keys(props.login.data).length > 0
    ) {
      if (props.login.data.is_two_step_auth_enabled == 1) {
        navigate("/verification");
      } else if (props.login.data.is_email_verified == 0) {
        navigate("/register-verification");
      } else {
        if (localStorage.getItem("product")) {
          let product = localStorage.getItem("product");
          localStorage.removeItem("product");
          navigate(`/product/${product}`);
        } else {
          navigate("/");
        }
      }
    }
    setSkipRender(false);
  }, [props.login]);

  useEffect(() => {
    if (
      !skipRender &&
      !props.register.loading &&
      Object.keys(props.register.data).length > 0
    ) {
      if (props.register.data.is_email_verified === 0) {
        navigate("/register-verification");
      } else {
        if (localStorage.getItem("product")) {
          let product = localStorage.getItem("product");
          localStorage.removeItem("product");
          navigate(`/product/${product}`);
        } else {
          navigate("/");
        }
      }
    }
    setSkipRender(false);
  }, [props.register]);

  return (
    <>
      <div className="login-sec">
        <Container>
          <div className="login-title">
            {/* <h4>{t("already_registered")}</h4> */}
          </div>
          <Row className="align-items-center justify-content-center g-4">
            <Col xl={5} md={12} lg={5}>
              <div className="login-right-sec">
                {/* <div className="login-img mobile-img login-bg">
                  <Image
                    src={window.location.origin + "/images/login-img.png"}
                    type="image/png"
                  />
                </div> */}
                <div className="login-content">
                  <h4>{t("new_customer")}</h4>
                  <p>{t("login_text")}</p>
                  <Link to="/register" className="wishlist-btn">
                    {t("create_account")}
                  </Link>
                </div>
              </div>
            </Col>
            <Col xl={6} md={12} lg={7}>
              <div className="login-form">
                <h3>{t("login")}</h3>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={loginSchema}
                  onSubmit={(values) => handleLogin(values)}
                >
                  {({ touched, errors }) => (
                    <FORM>
                      <div className="required-content">
                        <p>{t("required_fields")}*</p>
                      </div>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{t("email")}*</Form.Label>
                        <Field
                          type="email"
                          name="email"
                          placeholder={t("email_placeholder")}
                          className="form-control"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="email"
                          className="text-danger text-right"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-4"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>{t("password")}*</Form.Label>

                        <div className="input-group d-flex align-items-center">
                          <Field
                            type={loginPasswordVisible ? "text" : "password"}
                            name="password"
                            placeholder={t("password_placeholder")}
                            className="form-control"
                          />
                          <div className="input-group-append">
                            <button
                              onClick={() =>
                                setLoginPasswordVisible(!loginPasswordVisible)
                              }
                              className="btn password-eye"
                              type="button"
                            >
                              {loginPasswordVisible ? (
                                <i className="fas fa-eye-slash align-self-center"></i>
                              ) : (
                                <i className="fas fa-eye align-self-center"></i>
                              )}
                            </button>
                          </div>
                        </div>
                        <ErrorMessage
                          component={"div"}
                          name="password"
                          className="text-danger text-right"
                        />
                      </Form.Group>
                      <div className="login-btn default-btn-sec">
                        <Button
                          className="default-btn"
                          type="submit"
                          disabled={props.login.buttonDisable}
                        >
                          {props.login.loadingButtonContent
                            ? props.login.loadingButtonContent
                            : t("login")}
                        </Button>
                        <Link to="/forgot-password">{t("lost_password?")}</Link>
                      </div>
                    </FORM>
                  )}
                </Formik>
                <div className="login-social-icon mt-3">
                  {configuration.get("configData.GOOGLE_CLIENT_ID") ||
                  configuration.get("configData.FB_CLIENT_ID") ? (
                    <span className="or-line">
                      <p>{t("login_with")}</p>
                    </span>
                  ) : null}

                  <div className="login-icons">
                    <ul>
                      <li>
                        {configuration.get("configData.FB_CLIENT_ID") ? (
                          <LoginSocialFacebook
                            appId={configuration.get(
                              "configData.social_logins.FB_CLIENT_ID"
                            )}
                            fieldsProfile={
                              "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
                            }
                            redirect_uri={REDIRECT_URI}
                            onResolve={({ provider, data }) => {
                              handleFacebookLogin(data);
                            }}
                            onReject={(err) => {
                              console.log(err);
                            }}
                          >
                            <FacebookLoginButton />
                          </LoginSocialFacebook>
                        ) : null}
                      </li>
                      <li>
                        {configuration.get("configData.GOOGLE_CLIENT_ID") ? (
                          <LoginSocialGoogle
                            client_id={configuration.get(
                              "configData.social_logins.GOOGLE_CLIENT_ID"
                            )}
                            redirect_uri={REDIRECT_URI}
                            scope="openid profile email"
                            discoveryDocs="claims_supported"
                            access_type="offline"
                            onResolve={({ provider, data }) => {
                              handleGoogleLogin(data);
                            }}
                            onReject={(err) => {
                              console.log(err);
                            }}
                          >
                            <GoogleLoginButton />
                          </LoginSocialGoogle>
                        ) : null}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  login: state.users.loginInputData,
  register: state.users.register,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(LoginIndex));
