import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  Button,
  Accordion,
} from "react-bootstrap";
import { contactSubmitStart } from "../store/actions/HomePageAction";
import { Link } from "react-router-dom";
import "./Contact.css";
import { withTranslation, useTranslation } from "react-multi-lang";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import configuration from "react-global-configuration";
import { connect } from "react-redux";
import FAQIndex from "../landingPage/FAQIndex";

const ContactUs = (props) => {
  const t = useTranslation();
  const [skipRender, setSkipRender] = useState(true);
  const contactRef = useRef();

  const contactSchema = Yup.object().shape({
    name: Yup.string().required(t("name_required")),
    email: Yup.string().email(t("invalid_email")).required(t("email_required")),
    query: Yup.string().required(t("query_required")),
    mobile: Yup.string().matches(
      /^(?=.*[0-9])(?=.{9,})/,
      t("must_contain_9_characters")
    ),
  });

  useEffect(() => {
    if (
      !skipRender &&
      !props.contactSubmit.loading &&
      Object.keys(props.contactSubmit.data).length > 0
    ) {
      contactRef.current.resetForm();
    }
    setSkipRender(false);
  }, [props.contactSubmit]);

  const handleSubmit = (values) => {
    props.dispatch(contactSubmitStart(values));
  };

  return (
    <>
      <div className="contact-sec">
        <div className="new-contact-sec">
          <div className="contact-sec-bg">
            <Container>
              <div className="contact-banner-content">
                <h5>{t("contact")}</h5>
                <h2>
                  {t("get_in_touch_with")} <br /> {t("we_are_ready_to_help")}
                </h2>
              </div>
            </Container>
          </div>
        </div>

        <Container>
          <div className="contact-form contact-form-padding">
            <Row className=" gy-4">
              <Col md={6}>
                <div className="new-contact-content">
                  <h4>{t("we_are_happy_to_hear_from_you")}</h4>
                  <p>
                    {t("dont_hesitate_to_contact_us")}
                  </p>
                  <div class="new-contact-address mt-4">
                    <h4>{t("india")}</h4>
                    {configuration.get("configData.contact_address") ? (
                      <div class="new-contact-link">
                        <div class="contact-address-img">
                          <Image
                            src={
                              window.location.origin + "/images/location.svg"
                            }
                            type="image/png"
                          />
                        </div>
                        <p>{configuration.get("configData.contact_address")}</p>
                      </div>
                    ) : null}
                    {configuration.get("configData.contact_mobile") ? (
                      <div class="new-contact-link">
                        <a
                          href={`tel:${configuration.get(
                            "configData.contact_mobile"
                          )}`}
                        >
                          <div class="contact-address-img">
                            <Image
                              src={window.location.origin + "/images/call.svg"}
                              type="image/png"
                            />
                          </div>
                        </a>

                        <p>{configuration.get("configData.contact_mobile")}</p>
                      </div>
                    ) : null}
                    {configuration.get("configData.contact_email") ? (
                      <div class="new-contact-link">
                        <a
                          href={`mailto:${configuration.get(
                            "configData.contact_email"
                          )}`}
                        >
                          <div class="contact-address-img">
                            <Image
                              src={window.location.origin + "/images/mail.svg"}
                              type="image/png"
                            />
                          </div>
                        </a>

                        <p>{configuration.get("configData.contact_email")}</p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="contact-form-details">
                  <div className="login-form">
                    <h3>{t("send_us_a_message")}</h3>
                    <p>
                      {t("fill_the_form")}
                    </p>
                    <Formik
                      initialValues={{
                        name: "",
                        email: "",
                        query: "",
                        mobile: "",
                      }}
                      validationSchema={contactSchema}
                      onSubmit={(values) => handleSubmit(values)}
                      enableReinitialize={true}
                      innerRef={contactRef}
                    >
                      <FORM>
                        <Form.Group className="mb-5">
                          <Field
                            type="text"
                            name="name"
                            placeholder={t("name_placeholder")}
                            className="form-control"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="name"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        <Form.Group className="mb-5">
                          <Field
                            type="number"
                            name="mobile"
                            placeholder={t("mobile_placeholder")}
                            className="form-control"
                            min="0"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="mobile"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        <Form.Group className="mb-5">
                          <Field
                            type="email"
                            name="email"
                            placeholder={t("email_placeholder")}
                            className="form-control"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        <Form.Group
                          className="mb-5"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Field
                            as="textarea"
                            name="query"
                            placeholder={t("query_placeholder")}
                            className="form-control"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="query"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        <div className="login-btn default-btn-sec">
                          <Button className="default-btn" type="submit">
                            {t("send_message")}
                          </Button>
                        </div>
                      </FORM>
                    </Formik>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <FAQIndex />
        </Container>
        {/* <div className="location-map-sec">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.0500684385433!2d-122.40763478500494!3d37.78886637975676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80858089179bab83%3A0xa22f6f7dfec336da!2s210%20Post%20St%2C%20San%20Francisco%2C%20CA%2094108%2C%20USA!5e0!3m2!1sen!2sin!4v1659968709508!5m2!1sen!2sin"
            width="100%"
            height="250"
            frameborder="0"
            style={{ border: 0 }}
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div> */}
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  contactSubmit: state.homepage.contactSubmit,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ContactUs));
