import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  Image,
  Nav,
  Row,
  Col,
  Button,
  Collapse,
  Form,
  InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  fetchProductWishlistListStart,
  fetchMoreProductWishlistListStart,
} from "../store/actions/ProductAction";
import NoDataFound from "../Helper/NoDataFound";
import ProductCard from "../Product/ProductCard";
import { withTranslation, useTranslation } from "react-multi-lang";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import InfiniteScroll from "react-infinite-scroll-component";
import NoWishListItemFound from "../Helper/NoWishListItemFound";

const WishListIndex = (props) => {

  const t = useTranslation();


  useEffect(() => {
    props.dispatch(fetchProductWishlistListStart({
      skip: 0,
      take: 12,
    }));
  }, []);

  const fetchMoreProducts = () => {
    props.dispatch(fetchMoreProductWishlistListStart({
      skip: props.productWishlists.data.products.length,
      take: 12,
    }));
  }

  return (
    <>
      <div className="product-search-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="section-title-sec">
                <h3>{t("wishlist")}</h3>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={12}>
              {/* <div className="wish-list-box"> */}
              {props.productWishlists.loading ?(
              <div className="wish-list-box">
                {[...Array(8)].map((elementInArray, index) => (
                  
                  <Skeleton
                    count={1}
                    height={300}
                  />
                  
                ))}
                </div>
                ) : props.productWishlists.data.products &&
                  props.productWishlists.data.products.length > 0 ? (
                  <InfiniteScroll
                    dataLength={props.productWishlists.data.products.length}
                    next={fetchMoreProducts}
                    hasMore={props.productWishlists.data.products.length <
                      props.productWishlists.data.total_products}
                    loader={<div className="wish-list-box">
                    <Skeleton
                      count={1}
                      height={300}
                    />
                    </div>}
                  >
                    <div className="wish-list-box">
                      {props.productWishlists.data.products.map((product, i) =>
                        <ProductCard product={product} key={i} />
                      )}
                    </div>
                  </InfiniteScroll>) :
                  <NoWishListItemFound/>}
              {/* </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  productWishlists: state.product.productWishlists,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(withTranslation(WishListIndex));
