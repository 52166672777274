import {
  SAVE_DELIVERY_ADDRESSES_START,
  SAVE_DELIVERY_ADDRESSES_SUCCESS,
  SAVE_DELIVERY_ADDRESSES_FAILURE,
  FETCH_DELIVERY_ADDRESSES_LIST_START,
  FETCH_DELIVERY_ADDRESSES_LIST_SUCCESS,
  FETCH_DELIVERY_ADDRESSES_LIST_FAILURE,
  MAKE_DEFAULT_DELIVERY_ADDRESSES_START,
  MAKE_DEFAULT_DELIVERY_ADDRESSES_SUCCESS,
  MAKE_DEFAULT_DELIVERY_ADDRESSES_FAILURE,
  DELETE_DELIVERY_ADDRESSES_START,
  DELETE_DELIVERY_ADDRESSES_SUCCESS,
  DELETE_DELIVERY_ADDRESSES_FAILURE,
  FETCH_SINGLE_DELIVERY_ADDRESS_START,
  FETCH_SINGLE_DELIVERY_ADDRESS_SUCCESS,
  FETCH_SINGLE_DELIVERY_ADDRESS_FAILURE,  
} from "../actions/ActionConstant";

const initialState = {
  deliveryAddressesSave: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false
  },
  deliveryAddressesList: {
    data: {},
    loading: true,
    error: false,
  },
  makeDefaultDeliveryAddresses: {
    data: {},
    loading: true,
    error: false,
  },
  deliveryAddressesDelete: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false
  },
  singleDeliveryAddress: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: null,
    buttonDisable: false,
  }
};

const deliveryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_DELIVERY_ADDRESSES_START:
      return {
        ...state,
        deliveryAddressesSave: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Uploading....",
          buttonDisable: true
        },
      };
    case SAVE_DELIVERY_ADDRESSES_SUCCESS:
      return {
        ...state,
        deliveryAddressesSave: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false
        },
      };
    case SAVE_DELIVERY_ADDRESSES_FAILURE:
      return {
        ...state,
        deliveryAddressesSave: {
          data: {},
          loading: true,
          error: action.error,
          loadingButtonContent: "",
          buttonDisable: false
        },
      };
    case FETCH_DELIVERY_ADDRESSES_LIST_START:
      return {
        ...state,
        deliveryAddressesList: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_DELIVERY_ADDRESSES_LIST_SUCCESS:
      return {
        ...state,
        deliveryAddressesList: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_DELIVERY_ADDRESSES_LIST_FAILURE:
      return {
        ...state,
        deliveryAddressesList: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case MAKE_DEFAULT_DELIVERY_ADDRESSES_START:
      return {
        ...state,
        makeDefaultDeliveryAddresses: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case MAKE_DEFAULT_DELIVERY_ADDRESSES_SUCCESS:
      return {
        ...state,
        makeDefaultDeliveryAddresses: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case MAKE_DEFAULT_DELIVERY_ADDRESSES_FAILURE:
      return {
        ...state,
        makeDefaultDeliveryAddresses: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case DELETE_DELIVERY_ADDRESSES_START:
      return {
        ...state,
        deliveryAddressesDelete: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "Uploading....",
          buttonDisable: true
        },
      };
    case DELETE_DELIVERY_ADDRESSES_SUCCESS:
      return {
        ...state,
        deliveryAddressesDelete: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: "",
          buttonDisable: false
        },
      };
    case DELETE_DELIVERY_ADDRESSES_FAILURE:
      return {
        ...state,
        deliveryAddressesDelete: {
          data: {},
          loading: true,
          error: action.error,
          loadingButtonContent: "",
          buttonDisable: false
        },
      };
    case FETCH_SINGLE_DELIVERY_ADDRESS_START:
      return {
        ...state,
        singleDeliveryAddress: {
          data: {},
          loading: true,
          error: false,
          loadingButtonContent: "loading",
          buttonDisable: true,
        },
      };
    case FETCH_SINGLE_DELIVERY_ADDRESS_SUCCESS:
      return {
        ...state,
        singleDeliveryAddress: {
          data: action.data,
          error: false,
          loading: false,
          loadingButtonContent: null,
          buttonDisable: false,
        }
      };
    case FETCH_SINGLE_DELIVERY_ADDRESS_FAILURE:
      return {
        ...state,
        singleDeliveryAddress: {
          data: {},
          loading: false,
          error: action.error,
          loadingButtonContent: null,
          buttonDisable: false,
        }
      }

    default:
      return state;
  }
}

export default deliveryReducer;